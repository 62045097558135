import React from 'react';
import ReactDOM from 'react-dom/client';
import Router from './Router';
import './styles/reset.scss';
import './styles/common.scss';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<Router />);

// For styled components
// import React from 'react';
// import ReactDOM from 'react-dom/client';
// import { ThemeProvider } from 'styled-components';
// import GlobalStyle from './styles/GlobalStyle';
// import theme from './styles/theme';
// import Router from './Router';

// const root = ReactDOM.createRoot(document.getElementById('root'));
// root.render(
//   <ThemeProvider theme={theme}>
//     <GlobalStyle />
//     <Router />
//   </ThemeProvider>,
// );
