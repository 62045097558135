import React, { useState } from 'react';
import './DeleteAccount.scss';

function DeleteAccount() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  const handleDeleteAccount = async () => {
    try {
      const loginResponse = await fetch(
        'https://alive-backend.akaai.io/auth/login/',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            custom_email_field: email,
            password: password,
          }),
        },
      );

      if (!loginResponse.ok) {
        const loginErrorData = await loginResponse.json();
        setErrorMessage(loginErrorData.message || 'Login failed.');
        return;
      }

      const loginData = await loginResponse.json();
      const token = loginData.data.token;

      const deleteResponse = await fetch(
        'https://alive-backend.akaai.io/auth/delete_user',
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${token}`,
          },
          body: JSON.stringify({
            reason: 'Account deleted from the website',
            detail: 'Account deleted from the website',
            password: password,
          }),
        },
      );

      if (deleteResponse.ok) {
        setSuccessMessage('Account deleted successfully.');
      } else {
        const deleteErrorData = await deleteResponse.json();
        setErrorMessage(deleteErrorData.message || 'Error deleting account.');
      }
    } catch (error) {
      setErrorMessage('An error occurred. Please try again.');
    }
  };

  return (
    <div className="delete-main">
      <h2>Delete Account for Alive</h2>
      {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
      {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
      <form className="delete-form">
        <label>
          Email:
          <input
            type="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            required
          />
        </label>
        <br />
        <label>
          Password:
          <input
            type="password"
            value={password}
            onChange={e => setPassword(e.target.value)}
            required
          />
        </label>
        <br />
        <button type="button" onClick={handleDeleteAccount}>
          Delete Account
        </button>
      </form>
      <p>
        This website is for the users want to delete the account without
        installing an app again. Once you delete your account it cannot be
        undone.
      </p>
    </div>
  );
}

export default DeleteAccount;
