// Header.js
import React, { useState } from 'react';
import './Header.scss';

function Header({ onMenuItemClick }) {
  const [menuOpen, setMenuOpen] = useState(false);

  const handleMenuClick = section => {
    if (onMenuItemClick) {
      onMenuItemClick(section);
      // Close the menu after clicking a menu item
      setMenuOpen(false);
    }
  };

  const toggleMenu = () => {
    console.log('Toggle menu');

    setMenuOpen(!menuOpen);
  };

  return (
    <div className={`header ${menuOpen ? 'menu-open' : ''}`}>
      <div className="logo">
        <img src="/images/header_logo.svg" alt="Logo" />
        <h1>alive</h1>
      </div>
      <div className={`menu ${menuOpen ? 'show' : ''}`}>
        <a onClick={() => handleMenuClick('Mood Diary')}>Mood Diary</a>
        <a onClick={() => handleMenuClick('AI Therapist')}>AI Counselor</a>
        <a onClick={() => handleMenuClick('Self-Assessment')}>
          Self-Assessment
        </a>
        <a href="https://akaintelligence.com/">About Us</a>
      </div>
      <div
        className={`hamburger-menu ${menuOpen ? 'open' : ''}`}
        onClick={toggleMenu}
      >
        <img
          className="hamburger"
          src={menuOpen ? '/images/x.svg' : '/images/hamburger.svg'}
          alt={menuOpen ? 'Close Menu' : 'Open Menu'}
        />
      </div>
    </div>
  );
}

export default Header;
