import React from 'react';
import './Footer.scss';

function Footer() {
  return (
    <div className="footer">
      <div className="area">
        <img src="/images/aka_logo.svg"></img>
        <div className="contact">
          <div className="title">
            {/* <a>Contact</a> */}
            <a>Email</a>
            <a>Address</a>
          </div>
          <div className="info">
            {/* <a>+82 2 537 7201</a> */}
            <a>info@akaintelligence.com</a>
            <a>1450 2nd Street #217, Santa Monica, CA 90401</a>
          </div>
        </div>
        <div className="policy">
          <a href="/terms">Terms of Service</a>
          <a href="privacy">Privacy</a>
        </div>
        <div className="copyright">© 2023 AKA</div>
      </div>
    </div>
  );
}

export default Footer;
