import React from 'react';
import './Terms.scss';

function Terms() {
  return (
    <div className="terms">
      <h3 style={{ textAlign: 'center' }}>Terms and Conditions of Service</h3>
      <p>
        <strong>1. General</strong>
      </p>
      <p>
        Alive (&ldquo;<strong>Application</strong>&rdquo;) and related services
        (together with the Application and the Website, the &ldquo;
        <strong>Service</strong>&rdquo;) are operated by AKA (&ldquo;
        <strong>AKA</strong>,&rdquo; &ldquo;<strong>Company</strong>,&rdquo; or
        &ldquo;<strong>we</strong>&rdquo;). Access and use of the Service are
        subject to the following Terms and Conditions of Service (&ldquo;
        <strong>Terms and Conditions</strong>&rdquo;). By accessing or using any
        part of the Service, you represent that you have read, understand, and
        agree to be bound by these Terms and Conditions, including any future
        modifications.
      </p>
      <p>
        AKA may amend, update, or change these Terms and Conditions. If we do
        this, we will post a notice that we have made changes to these Terms and
        Conditions in the Application or on the Website for at least seven days
        after the changes are posted and will indicate at the bottom of the
        Terms and Conditions the date these terms were last revised. Any
        revisions to these Terms and Conditions will become effective the
        earlier of:
      </p>
      <p>● the end of such 7-day period</p>
      <p>
        ● or the first time you access or use the Service after such changes.
      </p>
      <p>
        If you do not agree to abide by these Terms and Conditions, you are not
        authorized to use, access, or participate in the Service.
      </p>
      <p>
        PLEASE NOTE THAT THESE TERMS AND CONDITIONS CONTAIN A MANDATORY
        ARBITRATION OF DISPUTES PROVISION THAT REQUIRES THE USE OF ARBITRATION
        ON AN INDIVIDUAL BASIS TO RESOLVE DISPUTES IN CERTAIN CIRCUMSTANCES
        RATHER THAN JURY TRIALS OR CLASS ACTION LAWSUITS.
      </p>
      <p>
        IF YOU DO NOT AGREE TO ALL THE TERMS AND CONDITIONS OF THIS AGREEMENT,
        YOU MAY NOT ACCESS OR USE THE APPLICATION.
      </p>
      <p>
        <em>i. Notices</em>
      </p>
      <p>
        Under this Agreement, you consent to receive communications from AKA
        electronically. AKA will communicate with you by e-mail or posting
        notices on the Website or the Application. You agree that all
        agreements, notices, disclosures, and other communications that AKA
        provides to you electronically satisfy any legal requirement that such
        communications be in writing. Except as provided herein, all notices and
        communications to AKA shall be in writing and shall be deemed to have
        been duly given or made:
      </p>
      <p>● with delivery by hand, when delivered</p>
      <p>● with certified or registered mail, postage prepaid</p>
      <p>● or with delivery by internationally recognized overnight courier.</p>
      <p>
        Notices to AKA shall be sent to 9th Floor, 100, Cheonggyecheon-ro,
        Jung-gu, Seoul, Republic of Korea, 04542.
      </p>
      <p>
        <em>ii. Complete Agreement.</em>
      </p>
      <p>
        This Agreement contains the entire understanding between the parties and
        supersedes, replaces, and takes precedence over any prior understanding
        or oral or written agreement between the parties respecting the subject
        matter of this Agreement. There are no representations, agreements,
        arrangements, or understandings, oral or written, between the parties
        relating to the subject matter of this Agreement that are not fully
        expressed herein.
      </p>
      <p>
        <em>iii. Severability.</em>
      </p>
      <p>
        In the event any provision of this Agreement shall be held invalid, the
        same shall not invalidate or otherwise affect in any respect any other
        term or terms of this Agreement, which term or terms shall remain in
        full force and effect.
      </p>
      <p>
        <em>iv. Transfer and Assignment.</em>
      </p>
      <p>
        This Agreement and any rights and licenses granted hereunder, may not be
        transferred or assigned by you but may be assigned by AKA without
        restriction. Any attempted transfer or assignment in violation hereof
        shall be null and void. The terms of this Agreement bind and are inure
        to the benefit of each party and the party&rsquo;s successors and
        permitted assigns.
      </p>
      <p>
        <em>v. Non-Waiver.</em>
      </p>
      <p>
        No delay or omission by AKA in exercising any right under this Agreement
        shall operate as a waiver of that or any other right. A waiver or
        consent given by AKA on any occasion shall be effective only in that
        instance and shall not be construed as a bar or waiver of any right on
        any other occasion.
      </p>
      <p>
        <em>vi. Headings.</em>
      </p>
      <p>
        The headings and other captions in this Agreement are included solely
        for convenience of reference and will not control the meaning and
        interpretation of any provision of this Agreement.
      </p>
      <p>
        <em>vii. Contact Us.</em>
      </p>
      <p>
        Please contact us at
        <span style={{ color: '#0000ff' }}> info@akaintelligence.com</span> with
        any questions regarding this Agreement.
      </p>
      <p>
        <strong>2. Description of Application and Service</strong>
      </p>
      <p>
        The Service allows users to access and use various mental health care
        services, including therapy or activities. AKA may, at its sole
        discretion and at any time, update, change, suspend, make improvements
        to, or discontinue any Service aspect, temporarily or permanently.
      </p>
      <p>
        <strong>3.Registration and General Content</strong>
      </p>
      <p>
        <em>i.Registration</em>
      </p>
      <p>
        In connection with registering for and using the Service, you agree:
      </p>
      <p>
        ● to provide accurate, current, and complete information about you
        and/or your organization as requested by AKA
      </p>
      <p>
        ● to maintain the confidentiality of your password and other information
        related to the security of your account
      </p>
      <p>
        ● to maintain and promptly update any registration information you
        provide to AKA, to keep such information accurate, current, and complete
      </p>
      <p>
        ● to be fully responsible for all use of your account and for any
        actions that take place through your account
      </p>
      <p>
        <em>ii. General Content</em>
      </p>
      <p>
        As a condition of submitting any ratings, reviews, information, data,
        text, photographs, audio clips, audiovisual works, or other materials on
        the Service (&ldquo;Content&rdquo;), you hereby grant to AKA a
        royalty-free, perpetual, irrevocable, worldwide, nonexclusive,
        transferable, and sublicensable license to use, reproduce, copy, adapt,
        modify, merge, distribute, publicly display, create derivative works
        from, incorporate such content into other works; sublicense through
        multiple tiers the content, and acknowledge that this license cannot be
        terminated by you once your content is submitted to the Service. You
        represent that you own or have secured all legal rights necessary for
        the content submitted by you to be used by you, AKA, and others as
        described and otherwise contemplated in these Terms and Conditions. You
        understand that other users will have access to the content and that
        neither they nor AKA have any obligation to you or anyone else to
        maintain the confidentiality of the content.
      </p>
      <p>
        <strong>4.Rules and Prohibitions</strong>
      </p>
      <p>You may not:</p>
      <p>
        ● access or use the Application for any purpose that is unlawful or
        prohibited by this Agreement
      </p>
      <p>
        ● use the Application in any manner that, in our sole discretion, could
        damage, disable, overburden, or impair it or interfere with any other
        party&rsquo;s use of the Application
      </p>
      <p>
        ● intentionally interfere with or damage the operation of the
        Application, or any User&rsquo;s enjoyment of it, by any means,
        including uploading or otherwise disseminating viruses, worms, or other
        malicious code
      </p>
      <p>
        ● remove, circumvent, disable, damage, or otherwise interfere with any
        security-related features of the Application, features that prevent or
        restrict the use or copying of any content accessible through the
        Application, or features that enforce limitations on the use of the
        Application
      </p>
      <p>
        ● attempt to gain unauthorized access to the Application, or any part of
        it, other accounts, computer systems, or networks connected to the
        Application, or any part of it, through hacking, password mining, or any
        other means, or interfere or attempt to interfere with the proper
        working of the Application or any activities conducted on the
        Application
      </p>
      <p>
        ● obtain or attempt to obtain any materials or information through any
        means not intentionally made available through the Application
      </p>
      <p>
        ● use any robot, spider, scraper, or other automated means to access the
        Application for any purpose without our express written permission or
        bypass our robot exclusion headers or other measures we may use to
        prevent or restrict access to the Application
      </p>
      <p>
        ● utilize any trademark, logo, or other AKA materials without our
        express written consent
      </p>
      <p>
        ● modify the Application in any manner or form, or use modified versions
        of the Application, including (without limitation) for obtaining
        unauthorized access to the Application
      </p>
      <p>
        ● create more than one account, use another person&rsquo;s account
        without authorization, impersonate any person or entity; or forge or
        manipulate headers or identifiers to disguise the origin of any content
        transmitted through the Application
      </p>
      <p>
        ● in any way violate any copyrights, trade secrets, or other rights of
        any third party, including privacy or publicity rights
      </p>
      <p>
        ● engage in conduct or post content that is libelous, defamatory,
        abusive, threatening, harassing, hateful, obscene, offensive,
        humiliating to other people (publicly or otherwise), or otherwise
        violates any law or right of AKA or any third party
      </p>
      <p>
        ● share other people's private or personally identifiable information
        without their express authorization and permission
      </p>
      <p>● attempt to undertake any of the foregoing indirectly.</p>
      <p>
        You further agree to only engage in Sessions for the sole purpose of
        your mental health care at your own risk.
      </p>
      <p>
        <strong>5. Inappropriate Use</strong>
      </p>
      <p>
        You will not upload, display, or otherwise provide on or through the
        Service any content that:
      </p>
      <p>
        ● is libelous, defamatory, abusive, threatening, harassing, hateful,
        offensive, or otherwise violates any law or infringes upon the right of
        any third party (including copyright, trademark, privacy, publicity, or
        other personal or proprietary rights)
      </p>
      <p>
        ● or in AKA&rsquo;s sole judgment, is objectionable or which restricts
        or inhibits any other person from using the Service or which may expose
        AKA or its users to any harm or liability of any kind.
      </p>
      <p>
        <strong>6. Indemnification of AKA</strong>
      </p>
      <p>
        You agree to indemnify and hold harmless AKA, its affiliated companies,
        suppliers, licensors, and partners from any claims, losses, damages, or
        liabilities, including attorney&rsquo;s fees, arising out of your use or
        misuse of the Application, violation of the rights of any other person
        or entity, or any breach of this Agreement.
      </p>
      <p>
        AKA reserves the right, at our own expense, to assume the exclusive
        defense and control of any matter for which you are required to
        indemnify us, and you agree to cooperate with our defense of these
        claims.
      </p>
      <p>
        <strong>7. License to Application</strong>
      </p>
      <p>
        Subject to these Terms and Conditions, AKA grants you a
        non-transferable, non-exclusive license to download, install, and use
        one copy of each Application in object code form only on an interactive
        wireless device you own or control. You may not derive or attempt to
        derive the source code of all or any portion of any Application, permit
        any third party to derive or attempt to derive such source code or
        reverse engineer, decompile, disassemble, or translate any Application
        or any part thereof. AKA and its licensors own and shall retain all
        intellectual property rights and other rights in and to the Application
        and any changes, modifications, or corrections thereto.
      </p>
      <p>
        <em>i. Apple iOS.</em>
      </p>
      <p>
        If you access our Application using a mobile application that you
        download, access, and/or use and that runs on Apple&rsquo;s iOS
        operating system (an &ldquo;<strong>iOS App</strong>&rdquo;), you
        acknowledge and agree that:
      </p>
      <p>
        ● the iOS App may only be accessed and used on a device owned or
        controlled by you and using Apple&rsquo;s iOS operating system
      </p>
      <p>
        ● the terms of this Agreement are between you and AKA, and not with
        Apple
      </p>
      <p>
        ● Apple has no obligation at all to provide any support or maintenance
        services in relation to the iOS App, and if you have any maintenance or
        support questions about the iOS App, please contact AKA, not Apple
      </p>
      <p>
        ● except as otherwise expressly outlined in this Agreement, any claims
        relating to the possession or use of the iOS App are between you and AKA
        (and not between you, or anyone else, and Apple)
      </p>
      <p>
        ● in the event of any claim by a third party that your possession or use
        (by this Agreement) of the iOS App infringes any intellectual property
        rights, Apple will not be responsible or liable to you for that claim
      </p>
      <p>
        ● although this Agreement is entered between you and AKA (and not
        Apple), Apple, as a third-party beneficiary under this Agreement, will
        have the right to enforce these terms against you.
      </p>
      <p>In addition, you represent and warrant that:</p>
      <p>
        ● you are not, and will not be, located in any country that is the
        subject of a United States Government embargo or that has been
        designated by the United States Government as a &ldquo;
        <strong>terrorist supporting</strong>&rdquo; country
      </p>
      <p>
        ● you are not listed on any United States Government list of prohibited
        or restricted parties
      </p>
      <p>
        ● if the iOS App does not conform to any warranty applying to it, you
        may notify Apple, which will then refund the purchase price of the iOS
        App (if any) to you. Subject to that, and the maximum extent permitted
        by law, Apple does not give or enter into any warranty, condition, or
        other term about the iOS App and will not be liable to you for any
        claims, losses, costs, or expenses of whatever nature about the iOS App
        or as a result of you or anyone else using the iOS App or relying on any
        of its content.
      </p>
      <p>
        <em>ii. Google App.</em>
      </p>
      <p>
        If you access our Application on a mobile application that you download,
        access, and/or use from the Google Play Store (&ldquo;
        <strong>Google-Sourced Software</strong>&rdquo;):
      </p>
      <p>
        ● you acknowledge that this Agreement is between you and AKA only and
        not with Google, Inc. (&ldquo;<strong>Google</strong>&rdquo;)
      </p>
      <p>
        ● your use of Google-Sourced Software must comply with Google&rsquo;s
        then-current Google Play Store Terms of Service
      </p>
      <p>
        ● Google is only a provider of the Google Play Store, where you obtained
        the Google-Sourced Software
      </p>
      <p>
        ● AKA, and not Google, is solely responsible for its Google-Sourced
        Software
      </p>
      <p>
        ● Google has no obligation or liability to you concerning Google-Sourced
        Software or this Agreement
      </p>
      <p>
        ● you acknowledge and agree that Google is a third-party beneficiary to
        this Agreement as it relates to AKA&rsquo;s Google-Sourced Software.
      </p>
      <p>
        <strong>8. In-App Purchases</strong>
      </p>
      <p>
        In the Service, you may purchase a limited, personal, non-transferable,
        non-sublicensable, revocable license to the Contents solely for use
        through the subscription purchase. If you purchase an auto-renewing
        periodic subscription through the Service, your account will be billed
        continuously for the subscription until you terminate it as set forth
        below. After your initial subscription period, your subscription will
        automatically renew. If you do not wish your subscription to renew
        automatically or want to change or terminate your subscription, you will
        need to log in to your account and follow the instructions to terminate
        or change your subscription, even if you have deleted your account.
      </p>
      <p>
        You agree to pay all fees and applicable taxes incurred by you or anyone
        using an account registered to you. All information you provide in
        connection with a purchase or transaction or other monetary transaction
        interaction with the Service must be accurate, complete, and current.
        You agree to pay all charges incurred by users of your credit card,
        debit card, or other payment method used in connection with a purchase
        or transaction or other monetary transaction interaction with the
        Service at the prices in effect when such charges are incurred. You will
        pay any applicable taxes, if any, relating to any such purchases,
        transactions, or other monetary transaction interactions.
      </p>
      <p>
        AKA may manage, regulate, control, modify, or eliminate Contents at any
        time, with or without notice. AKA may update the pricing of
        subscriptions at any time at its sole discretion and add new Virtual
        Items for additional fees. AKA shall have no liability to you or any
        third party if AKA exercises any such rights.
      </p>
      <p>
        The transfer of subscriptions is prohibited in the Service. You shall
        not sell, redeem or otherwise transfer subscriptions to any person or
        entity, including but not limited to the Company, another user, or any
        third party.
      </p>
      <p>
        <strong>9. Payment Processors</strong>
      </p>
      <p>
        Third parties will process all financial transactions made in connection
        with the Service in accordance with their respective terms of use,
        privacy policy, and/or any applicable payment terms and conditions. We
        encourage you to learn about the practices of such third parties. In no
        event will AKA be responsible for the actions or inactions of any
        third-party payment processor, including, but not limited to, system
        downtime or payment service outages.
      </p>
      <p>
        <strong>10. Refund Policy</strong>
      </p>
      <p>
        Suppose AKA suspends or terminates your use of the Service or these
        Terms and Conditions, or you close your account voluntarily. In that
        case, you understand and agree that you will receive no refund or
        exchange of any kind, including for any Content or data associated with
        your use of the Service or for anything else.
      </p>
      <p>
        <strong>11.NO REPRESENTATIONS OR WARRANTIES BY AKA</strong>
      </p>
      <p>
        THE SERVICE, INCLUDING ALL IMAGES, AUDIO FILES, AND OTHER CONTENT
        THEREIN, AND ANY OTHER INFORMATION, PROPERTY, AND RIGHTS GRANTED OR
        PROVIDED TO YOU BY AKA ARE PROVIDED TO YOU ON AN &ldquo;AS IS&rdquo;
        BASIS. AKA AND ITS SUPPLIERS MAKE NO REPRESENTATIONS OR WARRANTIES OF
        ANY KIND WITH RESPECT TO THE SERVICE, EITHER EXPRESS OR IMPLIED AND ALL
        SUCH REPRESENTATIONS AND WARRANTIES, INCLUDING WARRANTIES OF
        MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE OR NON-INFRINGEMENT,
        ARE EXPRESSLY DISCLAIMED. WITHOUT LIMITING THE GENERALITY OF THE
        PRECEDING, AKA DOES NOT MAKE ANY REPRESENTATION OR WARRANTY OF ANY KIND
        RELATING TO ACCURACY, SERVICE AVAILABILITY, COMPLETENESS, INFORMATIONAL
        CONTENT, ERROR-FREE OPERATION, RESULTS TO BE OBTAINED FROM USE, OR
        NON-INFRINGEMENT. ACCESS AND USE OF THE SERVICE MAY BE UNAVAILABLE
        DURING PERIODS OF PEAK DEMAND, SYSTEM UPGRADES, MALFUNCTIONS, SCHEDULED
        OR UNSCHEDULED MAINTENANCE, OR FOR OTHER REASONS. SOME JURISDICTIONS DO
        NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES, SO THE ABOVE EXCLUSION
        MAY NOT APPLY TO YOU.
      </p>
      <p>
        <strong>
          12. LIMITATION ON TYPES OF DAMAGES/LIMITATION OF LIABILITY
        </strong>
      </p>
      <p>
        IN NO EVENT WILL AKA BE LIABLE TO YOU OR ANY THIRD PARTY CLAIMING
        THROUGH YOU (WHETHER BASED IN CONTRACT, TORT, STRICT LIABILITY OR OTHER
        THEORY) FOR INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY
        DAMAGES ARISING OUT OF OR RELATING TO THE ACCESS OR USE OF, OR THE
        INABILITY TO ACCESS OR USE, THE SERVICE OR ANY PORTION THEREOF,
        INCLUDING BUT NOT LIMITED TO THE LOSS OF USE OF THE SERVICE, INACCURATE
        RESULTS, LOSS OF PROFITS, BUSINESS INTERRUPTION, OR DAMAGES STEMMING
        FROM LOSS OR CORRUPTION OF DATA OR DATA BEING RENDERED INACCURATE, THE
        COST OF RECOVERING ANY DATA, THE COST OF SUBSTITUTE SERVICES OR CLAIMS
        BY THIRD PARTIES FOR ANY DAMAGE TO ANY RELATED DEVICES OR OTHER
        PROPERTY, EVEN IF AKA HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH
        DAMAGES. AKA&rsquo;S LIABILITY TO YOU OR ANY THIRD PARTY CLAIMING
        THROUGH YOU FOR ANY CAUSE WHATSOEVER, AND REGARDLESS OF THE FORM OF THE
        ACTION, IS LIMITED TO THE AMOUNT PAID, IF ANY, BY YOU TO AKA FOR THE
        SERVICE IN THE 12 MONTHS BEFORE THE INITIAL ACTION GIVING RISE TO
        LIABILITY. THIS IS AN AGGREGATE LIMIT. THE EXISTENCE OF MORE THAN ONE
        CLAIM HEREUNDER WILL NOT INCREASE THIS LIMIT.
      </p>
      <p>
        <strong>13. Termination</strong>
      </p>
      <p>
        AKA may terminate your access and use of the Service immediately at any
        time, for any reason, and at such time, you will have no further right
        to use the Service. You may terminate your account anytime by following
        the instructions available through the Service. The provisions of these
        Terms and Conditions relating to the protection and enforcement of
        AKA&rsquo;s proprietary rights, your representations and warranties,
        disclaimer of representations and warranties, release and indemnities,
        limitations of liability and types of damages, ownership of data and
        information, governing law and venue, and miscellaneous provisions shall
        survive any such termination.
      </p>
      <p>
        <strong>14. Proprietary Rights in Content</strong>
      </p>
      <p>
        All content available through the Service, including designs, text,
        graphics, images, information, software, audio, and other files, and
        their selection and arrangement, are the proprietary property of AKA or
        its licensors. No Content may be modified, copied, distributed, framed,
        reproduced, republished, downloaded, scraped, displayed, posted,
        transmitted, or sold in any form or by any means, in whole or in part,
        other than as expressly permitted in these Terms and Conditions. You may
        not use data mining, robots, scraping, or similar data gathering or
        extraction methods to obtain content. As between you and AKA, all data,
        information, and materials generated from your access and use of the
        educational activities made available on or through the Service shall be
        exclusively owned by AKA, and you shall not have any right to use such
        materials except as expressly authorized by these Terms and Conditions.
        All rights of AKA or its licensors not explicitly granted in these Terms
        and Conditions are reserved to AKA and its licensors.
      </p>
      <p>
        <strong>15. Trademarks</strong>
      </p>
      <p>
        &ldquo;AKA&rdquo; and all other trademarks, service marks, graphics, and
        logos used in connection with the Service are trademarks or service
        marks of AKA or their respective owners, and certain of them are
        registered with the United States Patent and Trademark Office. Access
        and use of the Service does not grant or provide you with the right or
        license to reproduce or otherwise use the AKA name or any AKA or
        third-party trademarks, service marks, graphics, or logos.
      </p>
      <p>
        <strong>16. Privacy</strong>
      </p>
      <p>
        Use of the Service is also governed by our Privacy Policy, a copy of
        which is located at Privacy Policy. By using the Service, you consent to
        the terms of the Privacy Policy.
      </p>
      <p>
        <strong>17. Promotion Code Terms</strong>
      </p>
      <p>
        AKA Promotion Codes (each a &ldquo;<strong>Promotional Code</strong>
        &rdquo;) are made available by AKA (either directly or through a
        partner) subject to the Terms and Conditions. Each Promotional Code is
        made available in connection with the form of an auto-renewing periodic
        subscription (as defined in the Terms and Conditions).
      </p>
      <p>
        <em>i.The Promotional Code.</em>
      </p>
      <p>Each Promotional Code provides access to content:</p>
      <p>● at the price advertised</p>
      <p>
        ● beginning the moment that you confirm your acceptance of the
        Promotional Code by submitting valid payment details that are accepted
        by AKA (the &ldquo;<strong>Promotional Period</strong>&rdquo;)
      </p>
      <p>
        ● in some circumstances, subject to an overall limit of allowed
        redemptions of that Promotional Code.
      </p>
      <p>By submitting your payment details, you:</p>
      <p>● confirm your acceptance of the Promotional Code advertised</p>
      <p>● accept and agree to these Promotional Code Terms</p>
      <p>● acknowledge and agree to the Terms and Conditions.</p>
      <p>
        <em>ii. Eligibility.</em>
      </p>
      <p>
        To be eligible for a Promotional Code, users must satisfy all of the
        following conditions (each an &ldquo;
        <strong>Eligible User&rdquo;</strong>):
      </p>
      <p>
        ● Unless you subscribe to a Promotional Code that is advertised as
        available to current subscribers, you must not be a current subscriber.
      </p>
      <p>
        ● Provide AKA with a valid and current payment method approved by AKA.
      </p>
      <p>
        ● Additional eligibility requirements (if any) as advertised from time
        to time in connection with a Promotional Code.
      </p>
      <p>
        Eligible Users may accept a Promotional Code once - previous users may
        not redeem the offer again.
      </p>
      <p>
        <em>iii. Availability.</em>
      </p>
      <p>
        A Promotional Code must be accepted before the applicable expiration
        date advertised, if any. Except where law prohibits, AKA reserves the
        right to modify, suspend, or terminate a Promotional Code at any time
        and for any reason, in which case we will not honor subsequent
        Promotional Code enrollments.
      </p>
      <p>
        <em>iv. Duration and cancellation.</em>
      </p>
      <p>
        Unless you cancel your subscription before the end of the Promotional
        Period, you will automatically become a recurring subscriber, and your
        subscription will continue to renew automatically unless you cancel
        before the end of the relevant subscription term pursuant to AKA&rsquo;s
        terms and conditions. The payment method you provided will automatically
        be charged for the then-current subscription price. You will lose access
        to the content if you cancel during the Promotional Period. You
        understand and agree that you will receive no refund or exchange of any
        kind, including for any Content or data associated with your use of the
        Service or anything else.
      </p>
      <p>
        <strong>18. Arbitration and Class Action Waiver</strong>
      </p>
      <p>
        <em>i. Arbitration.</em>
      </p>
      <p>
        These Terms and Conditions, its subject matter and AKA&rsquo;s and your
        respective rights under these Terms and Conditions, as well as any
        claim, cause of action, or dispute (&ldquo;claim&rdquo;) arising out of
        or related to these Terms and Conditions, shall be governed by and
        construed under the laws of the Republic of Korea, excluding the
        conflict of law provisions of that or any other jurisdiction, regardless
        of your country of origin or where you access the Service. ANY DISPUTE
        OR CLAIM RELATING IN ANY WAY TO THESE TERMS AND CONDITIONS OR THE
        SERVICE WILL BE RESOLVED EXCLUSIVELY THROUGH FINAL AND BINDING
        ARBITRATION, INSTEAD OF A COURT OR JURY TRIAL, except for AKA&rsquo;s
        right to seek injunctive relief as set forth below. Unless otherwise
        expressly required by applicable law, each party shall bear its own
        attorneys&rsquo; fees without regard to which party is deemed the
        prevailing party in the arbitration proceeding.
      </p>
      <p>
        Except as explicitly stated otherwise in this Arbitration and Class
        Action Waiver section, this arbitration agreement requires the
        arbitration of any claims that AKA or you may have against the other or
        against any of their:
      </p>
      <p>
        ● officers, directors, employees, subcontractors, or agents in their
        capacity as such or otherwise,
      </p>
      <p>● direct or indirect parents and subsidiaries,</p>
      <p>
        ● affiliates, agents, successors, or assigns, each and all of which may
        enforce this arbitration agreement as direct or third-party
        beneficiaries.
      </p>
      <p>
        If you intend to seek arbitration, you must first send AKA a written
        notice of your intent to arbitrate (&ldquo;<strong>Notice</strong>
        &rdquo;). Any of the following means should send the Notice to AKA:
      </p>
      <p>
        ● electronic mail to{' '}
        <span style={{ color: '#0000ff' }}>info@akaintelligence.com</span>
      </p>
      <p>
        ● Send the Notice by the Postal Service to AKA, Inc. (Attention: 17th
        Floor, 9th Floor, 100, Cheonggyecheon-ro, Jung-gu, Seoul, Republic of
        Korea, 04542.)
      </p>
      <p>The notice must</p>
      <p>● describe the nature and basis of the claim or dispute</p>
      <p>● set forth the specific relief sought</p>
      <p>● set forth your name, address, and contact information</p>
      <p>
        If we intend to seek arbitration against you, we will send any notice of
        dispute to you at the contact information we have for you.
      </p>
      <p>
        <em>ii. No Class Action.</em>
      </p>
      <p>
        To the fullest extent permitted by applicable law, YOU AND AKA EACH
        AGREE THAT ANY DISPUTE RESOLUTION PROCEEDING WILL BE CONDUCTED ONLY ON
        AN INDIVIDUAL BASIS AND NOT IN A CLASS, CONSOLIDATED OR REPRESENTATIVE
        ACTION. If, for any reason, a claim proceeds in court rather than in
        arbitration, YOU AND AKA EACH WAIVE ANY RIGHT TO A JURY TRIAL. Suppose a
        court of competent jurisdiction finds the foregoing arbitration
        provisions invalid or inapplicable. In that case, you and AKA agree that
        all claims arising out of or related to these Terms and Conditions must
        be resolved exclusively by a state or federal court located in South
        Korea. You and AKA each agree to submit to the exercise of personal
        jurisdiction of such courts for the purpose of litigating all such
        claims. Notwithstanding the above, you agree that AKA shall still be
        allowed to apply for and obtain injunctive remedies (or an equivalent
        type of urgent legal relief) in any jurisdiction.
      </p>
      <p>
        <em>iii. 30-Day Right to Opt-Out</em>
      </p>
      <p>
        If you do not want to arbitrate disputes with AKA and are an individual,
        you may opt out of this arbitration agreement by emailing
        <span style={{ color: '#0000ff' }}> info@akaintelligence.com</span>{' '}
        within 30 days of the day you first access or use the Service. If you
        opt out of these arbitration provisions, AKA also will not be bound by
        them.
      </p>
      <p>
        <em>iv. Changes to This Section.</em>
      </p>
      <p>
        AKA will provide thirty (30) days' notice of any changes affecting the
        substance of this section by posting on the Application, Website or
        otherwise notifying you when you are logged into your account.
        Amendments affecting the substance of this section will become effective
        thirty (30) days after they are posted on the Application or sent to
        you. Changes to this section will otherwise apply prospectively only to
        claims arising after the thirtieth (30th) day. Suppose a court or
        arbitrator decides this subsection on "
        <strong>Changes to This Section</strong>" is not enforceable or valid.
        In that case, this subsection shall be severed from the section entitled
        &ldquo;<strong>Arbitration and Class Action Waiver</strong>,&rdquo; the
        court or arbitrator shall apply the first Arbitration and Class Action
        Waiver section in existence after you began using the Application.
      </p>
      <p>
        <em>v. Survival.</em>
      </p>
      <p>
        This arbitration agreement survives after the termination of this
        Agreement and/or after you cease any relationship with AKA.
      </p>
      <p>
        <strong>19. Language</strong>
      </p>
      <p>
        This agreement was originally written in English (US). To the extent
        that any translated version of this agreement conflicts with the English
        version, the English version controls.
      </p>
      <p>
        <strong>20 . Miscellaneous</strong>
      </p>
      <p>
        These Terms and Conditions constitute the entire agreement between AKA
        and you concerning the subject matter hereof. In the event that any of
        the Terms and Conditions are held by a court or other tribunal of
        competent jurisdiction to be unenforceable, such provisions shall be
        limited or eliminated to the minimum extent necessary so that these
        Terms and Conditions shall otherwise remain in full force and effect. A
        waiver by AKA or you of any provision of these Terms and Conditions or
        any breach thereof, in any one instance, will not waive such term or
        condition or any subsequent breach thereof. AKA may assign its rights or
        obligations under these Terms and Conditions without condition. These
        Terms and Conditions will be binding upon and inure to the benefit of
        AKA and you, and AKA's and your respective successors and permitted
        assigns.
      </p>
      <p>Last revised on Nov 17, 2023</p>
    </div>
  );
}

export default Terms;
