import React from 'react';
import './Disclaimer.scss';

function Disclaimer() {
  return (
    <div className="disclaimer">
      <h3 style={{ textAlign: 'center' }}>Disclaimer</h3>

      <p>
        <strong>Alive</strong> provides information that may benefit personal
        mental health, but does not guarantee the accuracy of this information
        or medical practice. If you currently need to make a medical decision,
        visit your nearest doctor, or dial 911 in case of an emergency.
      </p>

      <p>Last revised on Nov 17, 2023</p>
    </div>
  );
}

export default Disclaimer;
