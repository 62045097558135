import React from 'react';
import './Terms.scss';

function TermsKR() {
  return (
    <div className="terms">
      <div>
        <h3>Alive 서비스 이용약관</h3>
      </div>
      <div>
        <h2
          style={{ textAlign: 'center', fontSize: '25px', fontWeight: 'bold' }}
        >
          제 1장 총칙
        </h2>
      </div>
      <div>
        <strong>제1조 (목적)</strong>
        <p />본 약관은 <strong>&lt;(주) 아카에이아이&gt;</strong>(이하
        &ldquo;회사&rdquo; 또는 &ldquo;아카에이아이&rdquo;라고 한다)가 Alive
        어플리케이션(이하 &ldquo;앱&rdquo;이라고 한다)으로 제공하는 디지털
        콘텐츠(이하 &ldquo;콘텐츠&rdquo;라고 한다) 및 제반 서비스의 이용과
        관련하여 회사와 이용자와의 권리, 의무 및 책임사항 등을 규정함을 목적으로
        합니다.
        <p />
        <p />
        <strong>제2조 (정의)</strong>
        <p />이 약관에서 사용하는 용어의 정의는 다음과 같습니다.
        <div>
          <p />
          1. &ldquo;회사&rdquo;라 함은 콘텐츠 산업과 관련된 경제활동을 영위하는
          자로서 콘텐츠 및 제반 서비스를 제공하는 자를 말합니다.
          <p />
          2. &ldquo;회원&rdquo;이라 함은 앱을 단말기에 다운로드 받은 후 , 본
          약관에 따라 회사가 제공하는 콘텐츠 및 제반서비스 이용 자격을 부여 받은
          자를 의미합니다.
          <p />
          3. "이용자"라 함은 "회사"의 사이트에 접속하여 이 약관에 따라 "회사"가
          제공하는 "콘텐츠" 및 제반서비스를 이용하는 회원 및 비회원을 말합니다.
          <p />
          4. &ldquo;서비스&rdquo;라 함은 회사가 앱을 통해 제공하는 모든 정신건강
          콘텐츠 및 AI Chat 서비스 일체를 의미합니다.
          <p />
          5. "유료서비스"란 회사가 회원에게 제공하는 서비스 중 서비스 이용의
          대가로 요금이 과금되는 서비스를 의미합니다.
          <p />
          6. &ldquo;구독&rdquo;이라 함은 회원이 서비스를 제공받기 위해 사용하는
          이용권입니다. 첫 결제일부터 구독을 취소할 때까지 매달 청구됩니다. 구독
          취소는 언제든지 가능합니다.
          <p />
          7. &ldquo;콘텐츠&rdquo;라 함은 정보통신망 이용촉진 및 정보보호 등에
          관한 법률 제2조 제1항 제1호의 규정에 의한 정보통신망에서 사용되는
          부호&middot;문자&middot;음성&middot;음향&middot;이미지 또는 영상
          등으로 표현된 자료 또는 정보로서, 그 보존 및 이용에 있어서 효용을 높일
          수 있도록 전자적 형태로 제작 또는 처리된 것을 말합니다.
        </div>
        <p />
        <strong>제3조 (신원정보 등의 제공)</strong>
        <p />
        회사는 이 약관의 내용, 상호, 대표자 성명, 영업소 소재지 주소 (소비자의
        불만을 처리할 수 있는 곳의 주소를 포함), 전화번호, 팩스번호,
        전자우편주소, 사업자 등록번호, 통신 판매업 신고번호 및 개인정보
        관리책임자 등을 이용자가 쉽게 알 수있도록 온라인 서비스 초기화면에
        게시합니다. 다만 약관은 이용자가 연결화면을 통하여 볼 수 있도록 할 수
        있습니다.
        <p />
        <p />
        <strong>제4조 (약관 외 준칙)</strong>
        <p />이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 「약관의
        규제에 관한 법률」, 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」등
        관련 법령 또는 관례에 따릅니다.
        <p />
        <p />
        <strong>제5조 (서비스 제공)</strong>
        <p />
        <div>
          <p />
          1. 회사는 회원에게 아래와 같은 서비스를 제공합니다.
          <p />
          &nbsp;a. 콘텐츠를 통한 정신 건강 서비스 및 관련 부가 서비스
          <p />
          2. 회사는 업무상 또는 기술상 특별한 지장이 없는 한 연중무휴 1일 24시간
          서비스 제공을 원칙으로 합니다. 다만, 시스템 정기점검, 서버의 증설 및
          교체, 새로운 콘텐츠의 추가, 각종 버그 패치, 새로운 서비스로의 교체 등
          운영상 필요한 경우, 일정 시간 또는 기간 동안 서비스를 일시 중지시킬 수
          있으며, 관련 법령 상 또는 서비스의 내용 및 운영상 필요한 경우, 1일
          특정 시간 동안에만 서비스를 제공할 수 있습니다. 위와 같은 경우 회사는
          사전에 그 내용 및 시간을 홈페이지 또는 개별 서비스 관련 홈페이지에
          공지합니다. 다만, 회사가 사전에 통지할 수 없는 부득이한 사유가 있는
          경우 사후에 통지할 수 있습니다.
          <p />
          3. 회사는 특정 서비스에 대해서는 유료로 제공할 수 있습니다.
          <p />
          4. 회사는 서비스를 제공 함에 있어 이용자에게 본 약관 외에 별도
          서비스별 약관의 체결을 요구할 수 있습니다. 별도의 약관이 요구되는
          서비스는 이용자가 해당 앱의 약관에 동의한 후 이용신청을 하고 그러한
          내용에 대해 회사가 승인 함으로써 이용할 수 있습니다.
          <p />
          5. 회사는 회원에게 서비스를 제공함에 있어 관련 법령, 회원의 연령,
          서비스의 이용 절차에 따라 일부 서비스 이용 등을 제한할 수 있습니다. 이
          경우 회사는 그 내용을 모바일 앱 내에서 사전에 안내합니다.
        </div>
        <p />
        <strong>제6조 (서비스의 중단)</strong>
        <div>
          <p />
          1. 회사는 천재지변 국가비상사태 등 회사가 정상적인 서비스 제공이
          불가능할 경우 일시적으로 서비스를 제한, 정지시킬 수 있으며 사전 또는
          사후 중지사유 및 기간을 홈페이지에 공지합니다.
          <p />
          2. 회사는 회사의 전산장비시설의 보수점검, 교체 및 통신상의 문제가
          발생한 경우 서비스 중지 1주일 전에 고지 후 서비스를 중지할 수 있으며,
          이 기간에 회원이 고지내용을 인지하지 못한 것에 대하여 회사는 책임을
          부담하지 아니합니다. 상당한 이유가 있을 경우 위 사전 고지 기간은
          감축되거나 생략될 수 있습니다. 다만, 회사가 사전에 통지할 수 없는
          부득이한 사유가 있는 경우 사후에 통지할 수 있습니다.
          <p />
          3. 회사는 서비스 내용의 결정, 변경, 유지, 중단에 관한 포괄적인 권한을
          가집니다. 회사는 영업의 폐지, 합병, 분할, 당해 서비스의 수익 악화,
          서비스 유지 등 상당한 이유가 있는 경우 제공하고 있는 전부 또는 일부
          서비스를 변경하거나 중단할 수 있습니다. 이와 같은 경우에는 회원에게
          통지하고 사용하지 않은 유료 수강권에 한해 회사에서 정한 조건에 따라
          보상합니다.
          <p />
          4. 회사는 서비스의 이용에 필요한 경우 정기점검을 할 수 있으며,
          정기점검 시간은 서비스 제공 화면에 공지한 바에 따릅니다.
        </div>
        <br />
        <br />
        <div>
          <h2
            style={{
              textAlign: 'center',
              fontSize: '25px',
              fontWeight: 'bold',
            }}
          >
            제2장 회원가입
          </h2>
        </div>
        <p />
        <p />
        <strong>제7조 (회원 가입)</strong>
        <div>
          <p />
          1. 회원가입은 이용자가 약관의 내용에 대하여 동의를 하고 회원가입신청을
          한 후 회사가 이러한 신청에 대하여 승낙함으로써 이루어집니다. 이용자가
          회원가입신청을 하는 경우 회사의 이용약관에 동의한 것으로 간주합니다.
          <p />
          2. 회사는 아래 해당 사항에 해당하는 경우 이용자의 회원 가입을 허가하지
          않을 수 있습니다.
          <div>
            <p />
            a. 등록 내용에 허위 기재, 누락, 오기가 있는 경우
            <p />
            b. 본 약관에서 정한 회원 탈퇴 및 자격 상실이 있던 회원인 경우
            <p />
            c. 타인의 명의를 이용한 경우
            <p />
            d. 이용자의 고의 또는 과실로 인하여 승낙할 수 없거나 회사의 규정을
            위반하여 신청한 경우
            <p />
            e. 기타 법령 위반 또는 회사의 규정을 위반하는 경우
          </div>
          <p />
          3. 회사는 이용자의 회원가입신청을 승낙하는 것을 원칙으로 합니다. 다만,
          회사는 서비스 관련 설비의 여유가 없거나 기술상 또는 업무상 문제가 있는
          경우, 기타 회사가 필요하다고 인정하는 경우에는 가입신청의 승낙을
          유보하거나 거절할 수 있습니다.
          <p />
          4. 회원가입 신청에 대하여 유보하거나 거절한 경우 회사는 이를
          신청자에게 알립니다. 다만, 회사가 고의 또는 과실 없이 가입 신청자에게
          통지할 수 없는 경우에는 그러하지 않습니다.
          <p />
          5. 회원가입계약의 성립 시기는 회사의 승낙이 회원에게 도달한 시점으로
          합니다.
        </div>
        <p />
        <strong>제8조 (미성년자의 회원가입 등에 관한 특칙)</strong>
        <div>
          <p />
          1. 만 14세 미만의 회원은 개인정보의 수집 및 이용목적에 대하여 충분히
          숙지하고 부모 등 법정대리인의 동의를 얻은 후에 회원가입을 신청하고
          본인의 개인정보를 제공하여야 합니다.
          <p />
          2. 회사는 부모 등 법정대리인의 동의에 대한 확인절차를 거치지 않은 14세
          미만 회원에 대하여는 가입을 취소 또는 불허합니다.
          <p />
          3. 만 14세 미만 회원의 부모 등 법정대리인은 아동에 대한 개인정보의
          열람, 정정, 갱신을 요청하거나 회원가입에 대한 동의를 철회할 수 있으며,
          이러한 경우에 회사는 지체없이 필요한 조치를 취해야 합니다.
        </div>
        <p />
        <strong>제9조 (회원정보의 변경)</strong>
        <div>
          <p />
          1. 회원은 개인정보관리화면을 통하여 언제든지 자신의 개인정보를
          열람하고 수정할 수 있습니다.
          <p />
          2. 회원은 회원가입 신청 시 기재한 사항이 변경되었을 경우 온라인으로
          수정을 하거나 전자우편 기타 방법으로 회사에 대하여 그 변경사항을
          알려야 합니다.
          <p />
          3. 제 2항의 변경사항을 회사에 알리지 않아 발생한 불이익에 대하여
          회사는 책임지지 않습니다.
        </div>
        <p />
        <strong>제10조 (회원에 대한 통지)</strong>
        <div>
          <p />
          1. 회사가 회원에 대한 통지를 하는 경우 회원이 지정한 전자우편주소로 할
          수 있습니다.
          <p />
          2. 회사는 회원 전체에 대한 통지의 경우 7일 이상 회사의 게시판에 게시
          함으로서 제 1항의 통지에 갈음할 수 있습니다. 다만, 회원 본인의 거래와
          관련하여 중대한 영향을 미치는 사항에 대하여는 제1항의 통지를 합니다.
        </div>
        <p />
        <strong>제11조(회원탈퇴 및 자격 상실 등)</strong>
        <div>
          <p />
          1. 회원은 회사에 언제든지 탈퇴를 요청할 수 있으며 회사는 즉시
          회원탈퇴를 처리합니다.
          <p />
          2. 회원이 다음 각호의 사유에 해당하는 경우, 회사는 회원자격을 제한 및
          정지시킬 수 있습니다.
          <p />
          3. 회사가 회원자격을 제한&middot;정지시킨 후, 동일한 행위가 2회 이상
          반복되거나 30일 이내에 그 사유가 시정되지 아니하는 경우 회사는
          회원자격을 상실시킬 수 있습니다.
          <p />
          4. 회사가 회원자격을 상실시키는 경우에는 회원등록을 말소합니다. 이
          경우 회원에게 이를 통지하고, 회원등록 말소 전 에 최소한 30일 이상의
          기간을 정하여 소명할 기회를 부여합니다.
        </div>
        <p />
        <br />
        <br />
        <div>
          <h2
            style={{
              textAlign: 'center',
              fontSize: '25px',
              fontWeight: 'bold',
            }}
          >
            제3장 콘텐츠 이용계약
          </h2>
        </div>
        <p />
        <strong>제12조 (이용계약의 체결 및 구독권 이용, 가격, 재구매)</strong>
        <div>
          <p />
          1. 이용 계약의 체결 : 회원이 앱을 통해서 회사가 정한 절차에 따라
          구독권 등을 구매하고 이에 상응하는 대금을 납부하는 것으로 서비스 이용
          계약이 체결됩니다. 서비스 이용 계약의 체결로 회원은 서비스를 이용할 수
          있는 권리를 취득합니다.
          <p />
          2. 구독권 : 구독권이란 회원이 AI Chat 서비스를 이용하기 위해 구매하는
          정기 구독권입니다.
          <p />
          3. 가격 : 구독권 등의 서비스 이용 요금은 회사가 지정한 요금체계에 의해
          회사의 웹사이트와 앱에 공지한 가격을 기준으로 정합니다. 해외 사용자의
          경우, 환율에 따라 일부 차이가 있을 수 있으며, 회사는 급격한 환율
          변동으로 인한 가격 수정이 필요한 경우 사전 고지 없이 요금제를 일부
          조정할 수 있습니다.
          <p />
          4. 이용 기간 : 모든 구독권은 총 기간 내 1개월 단위로 수강 회수가
          나뉘어서 이용 기간이 제공됩니다.
          <p />
          5. 재구매 : 기존 결제 상품의 이용기한이 남은 상태에서 재구매를 하는
          경우, 재구매 상품의 이용은 &lsquo;보유 수강권 중 사용 기간이 가장 긴
          수강권의 종료일 + 1일&rsquo;부터 가능합니다.
        </div>
        <p />
        <strong>제13조 대금지급 방법</strong>
        <p />
        콘텐츠 이용에 대한 대금지급방법은 다음 각 호의 방법 중 가능한 방법으로
        할 수 있습니다. 다만, 회사는 이용자의 지급방법에 대하여 어떠한 명목의
        수수료도 추가하여 징수하지 않습니다.
        <div>
          <p />
          1. 인앱 결제
          <p />
          2. 각종 카드결제
          <p />
          3. 회사가 지급한 쿠폰에 의한 결제
          <p />
          4. 기타 전자적 지급방법에 의한 대금지급 등
        </div>
        <p />
        <strong>제14조 (회사의 의무)</strong>
        <div>
          <p />
          1. 회사는 법령과 본 약관이 금지하거나 공공의 질서와 미풍양속에 반하는
          행위를 하지 않으며 본 약관이 정하는 바에 따라 지속적이고 안정적으로
          수업을 비롯한 콘텐츠를 제공하는 데 최선을 다해야 합니다.
          <p />
          2. 회사는 회원이 안전하게 서비스를 이용할 수 있도록 이용자의 개인정보
          보호를 위한 보안 시스템을 갖추어야 합니다.
          <p />
          3. 회사는 이용자가 원하지 않는 영리 목적의 광고성 전자우편을 발송하지
          않습니다. 단, 이용자가 동의할 시 발송할 수 있습니다.
        </div>
        <p />
        <strong>제16조 (이용자의 의무)</strong>
        <div>
          <p />
          1. 이용자는 아래 행위를 하여서는 아니 됩니다.
          <div>
            <p />
            a. 회원가입에 대한 정보 등록 또는 회원 정보 수정 시 허위 내용의 등록
            <p />
            b. 제 3자에 대한 계정정보 및 비밀번호 제공 및 대여
            <p />
            c. 타인 정보 이용
            <p />
            d. 회사의 저작권 등 지적재산권에 대한 침해
            <p />
            e. 회사 또는 기타 제삼자의 명예를 훼손하거나 업무를 방해하는 행위
            <p />
            f. 기타 본 약관 및 관련 법령을 위반하는 일체의 불법적인 행위
          </div>
          <p />
          2. 회사는 회원이 위 조항들에 명시된 금지된 행위를 하였는지 여부를 검토
          후 회원의 정지 및 탈퇴 여부를 결정할 수 있습니다.
        </div>
        <p />
        <strong>제17조 (저작권의 귀속 및 이용제한)</strong>
        <div>
          <p />
          1. 회사는 앱에 게시된 콘텐츠 등 회사가 창작한 저작물에 대한 저작권 및
          기타 지식재산권을 소유합니다.
          <p />
          2. 이용자는 회사의 앱을 이용함으로써 얻은 정보 중 회사 소유
          지식재산권에 관한 정보를 회사의 사전 승낙 없이 복제, 송신, 출판, 배포,
          방송 기타 방법에 의하여 영리 목적으로 이용하거나 제삼자에게 이용하게
          하여서는 안 됩니다.
          <p />
          3. 이미지, 사진, 음성녹음, 상표, 로고, 글과 서비스와 관련된 모든
          매체의 저작권과 소유권은 모두 회사 그리고 제휴된 회사들에 귀속됩니다.
          이용자들은 회사의 저작권에 위반할 수 없으며 인쇄, 업로드, 잡지 또는
          웹사이트에 올리거나 제3자에게 배포할 수 없습니다.
        </div>
        <br />
        <br />
        <div>
          <h2
            style={{
              textAlign: 'center',
              fontSize: '25px',
              fontWeight: 'bold',
            }}
          >
            제4장 콘텐츠 이용계약의 청약철회, 계약해제&middot;해지 및 이용제한
          </h2>
        </div>
        <p />
        <strong>제18조 (회원의 청약철회)</strong>
        <div>
          <p />
          1. 회사와 콘텐츠 구매 계약을 체결한 회원은 다음 각 호에서 정한 기간
          내에 청약을 철회할 수 있습니다. 다만, 2주 무료체험 이용 후 결제한
          회원은 본 청약철회권을 행사 할 수 있는 대상자에서 제외됩니다.
          <p />
          2. 제1항의 청약철회권은 다음 각 호의 어느 하나에 해당할 경우 행사할 수
          없습니다. 다만, 회사는 제2호부터 제4호까지에 해당하여 회원의
          청약철회권을 배제하기 위하여 청약철회를 할 수 없다는 사실을 콘텐츠
          또는 그 포장 기타 이용자 및 회원이 쉽게 알 수 있는 곳에 표시하거나
          시용상품을 제공하거나 콘텐츠의 한시적 또는 일부 이용이 가능하게 하는
          등의 방법으로 청약철회권의 행사가 방해받지 아니하도록 조치를 취합니다.
          <div>
            <p />
            a. 회원의 책임 있는 사유로 콘텐츠가 멸실 또는 훼손된 경우
            <p />
            b. 회원의 사용 또는 일부 소비에 의하여 콘텐츠의 가치가 현저히 감소한
            경우
            <p />
            c. 시간의 경과에 의하여 재판매가 곤란할 정도로 콘텐츠의 가치가
            현저히 감소한 경우
            <p />
            d. 복제가 가능한 콘텐츠의 포장을 훼손한 경우
            <p />
            e. 회원의 주문에 의하여 콘텐츠가 개별적으로 생산되는 경우 등
            청약철회를 인정하면 회사에 회복할 수 없는 중대한 피해가 예상되는
            경우로서 사전에 당해 거래에 대하여 별도로 그 사실을 고지하고 회원의
            서면(전자문서를 포함한다)에 의한 동의를 얻은 경우
          </div>
          3. 제1항 및 제2항에도 불구하고 회원은 다음 각 호의 어느 하나에
          해당하는 사유가 있을 때는 당해 콘텐츠를 이용할 수 있는 날로부터 3월
          이내 또는 그 사실을 안 날 또는 알 수 있었던 날부터 30일 이내에 콘텐츠
          구매계약에 대한 청약을 철회할 수 있습니다.
          <div>
            <p />
            a. 표시ㆍ광고된 내용과 다른 콘텐츠가 제공된 경우
            <p />
            b. 계약내용과 다르게 이행된 경우
          </div>
          <p />
          4. 회원은 제1항 또는 제3항의 규정에 의한 청약의 철회를 이메일 등으로
          할 수 있습니다.
          <p />
          5. 회원이 제1항 또는 제3항의 규정에 의한 청약철회권을 서면(전자문서
          포함)으로 행사하는 경우 그 의사표시가 기재된 서면을 발송한 날에 그
          효력이 발생합니다.
          <p />
          6. 회사는 청약철회의 의사표시를 수신한 후 지체 없이 이의 수신사실을
          회원에게 회신합니다.
        </div>
        <p />
        <strong>제19조 (회원의 계약해제&middot;해지)</strong>
        <div>
          <p />
          1. 회원은 다음 각 호의 하나의 사유가 있는 경우 콘텐츠 구매계약을 해제
          또는 해지할 수 있습니다.
          <div>
            <p />
            a. 콘텐츠가 제공시기까지 제공되지 않은 경우
            <p />
            b. 하자있는 콘텐츠의 제공으로 인하여 회원이 이용목적을 달성할 수
            없는 경우
            <p />
            c. 장애발생 누적시간이 72시간을 초과한 경우
            <p />
            d. 회원이 변경약관을 거절하거나 동의한 것으로 간주되는 경우
            <p />
            e. 회사가 콘텐츠 이용조건 등의 변경 사유 등을 게시하지 않거나 회원이
            이용조건 변경을 거절한 경우
          </div>
          <p />
          다만, 회사가 다음 각 호중 하나의 조치를 취한 경우에 는 회원의
          청약철회권이 제한될 수 있습니다.
          <div>
            <p />
            a. 청약의 철회가 불가능한 콘텐츠에 대한 사실을 표시사항에 포함한
            경우
            <p />
            b. 시용상품을 제공한 경우
            <p />
            c. 한시적 또는 일부이용 등의 방법을 제공한 경우
          </div>
          <p />
          2. 회원이 제1항 제1호 또는 제2호의 사유로 콘텐츠 구매계약을 해제 또는
          해지하기 위하여는 상당한 기간을 정하여 회사에 대하여 콘텐츠의 이용을
          가능하게 하거나 하자 없는 콘텐츠를 제공할 것을 요구하여야 합니다.
          다만, 회원이 당해 제공시기에 콘텐츠를 제공받지 아니하면 그 계약의
          목적을 달성할 수 없는 경우 또는 회사가 사전에 콘텐츠를 제공하지 않을
          것을 명시한 경우에는 그러하지 않습니다.
        </div>
        <p />
        <strong>제20조 (회사의 계약해제&middot;해지 및 이용제한)</strong>
        <div>
          <p />
          1. 회사는 회사가 본 약관에서 금지하는 행위를 한 회원에게 시정을
          요구하였음에도 불구하고, 30일 이내에 그 사유가 시정되지 않은 경우
          콘텐츠 구매계약을 해제 또는 해지할 수 있습니다.
          <p />
          2. 본 조의 해제&middot;해지는 본 약관에서 정하고 있는 통지방법에 따라
          회원에게 그 의사표시가 도달한 때에 효력이 발생합니다.
          <p />
          3. 회원은 회사의 해제&middot;해지 및 이용제한에 대하여 이의신청을 할
          수 있습니다. 회원의 이의신청이 정당한 경우 회사는 즉시 회원이 콘텐츠를
          이용할 수 있도록 필요한 조치를 취합니다.
        </div>
        <p />
        <strong>제21조(유료 콘텐츠 상품)</strong>
        <p />
        <div>
          <p />
          1. 본 서비스 내에서 판매하는 유료 콘텐츠 상품의 종류는 다음과
          같습니다. 유료 콘텐츠 상품의 제공 내용, 가격, 결제 등 세부사항은
          결제화면 등에 게시하여 회원에게 고지합니다.
          <p />① 자동 결제 상품 : 인앱 결제를 통하여 일정 기간(월/년) 단위로
          이용요금이 자동으로 결제되고 이용기간이 자동 갱신되는 서비스
          <p />② 일반 결제 상품 : 회원이 등록한 결제수단을 통하여 콘텐츠를 구매
          후 소장&middot;이용하는 서비스
          <p />
          2. 회원은 유료 서비스를 구매하기 전에 각 이용 안내에서 공지하는 콘텐츠
          이용 가능 단말기의 사양, 유,무선 통신망의 품질 등이 해당 콘텐츠의
          이용에 적합한지 여부를 사전에 확인하여야 합니다.
          <p />
          3. 회사는 교육 외의 기타 콘텐츠나 부가서비스가 함께 제공되는 결합상품,
          다른 회사와의 계약을 통한 제휴 상품 등을 제공할 수 있습니다.
          <p />
          4. 회사의 귀책사유로 인하여 회원이 유료 콘텐츠 이용 기간 동안 당해
          콘텐츠를 이용할 수 없게 된 경우, 회사는 콘텐츠 이용기간을 연장하거나,
          이와 동일, 유사한 콘텐츠를 다시 제공하는 등의 방식으로 보상합니다.
        </div>
        <p />
        <strong>제23조 (유료콘텐츠 상품 결제, 취소 및 환불)</strong>
        <p />
        <div>
          <p />
          1. 유료 콘텐츠 상품 구매에 따른 결제는 회사가 정한 결제 수단으로 할 수
          있으며, 결제 가능한 수단은 서비스 내 결제화면 등에 표시됩니다.
          <p />
          2. 서비스 내에서 제공되는 콘텐츠의 특성 상 특별한 사정이 없는 한, 이미
          결제한 상품에 대한 결제 취소 및 변경, 환불은 원칙적으로 불가합니다.
          <p />
          3. 회원의 신청 또는 동의에 따라 자동결제 중인 유료 콘텐츠 상품의 경우,
          해당 회원이 유료 콘텐츠 상품의 이용요금을 체납할 시 연체가 발생한 날
          자동으로 상품 해지가 될 수 있으므로, 자동결제를 통한 혜택을 유지하고자
          하는 회원은 이용요금의 체납 또는 결제수단의 연체가 발생하지 않도록
          사전에 조치하여야 합니다.
          <p />
          4. 자동결제 유료 콘텐츠 상품을 이용 중인 회원이 탈퇴하는 경우 해당
          상품은 즉시 해지되며, 이 경우 회원의 정보 등 관련 기록은 관련 법령이
          정하는 경우를 제외하고는 완전히 삭제됩니다.
        </div>
        <p />
        <p />
        <div>
          <h2
            style={{
              textAlign: 'center',
              fontSize: '25px',
              fontWeight: 'bold',
            }}
          >
            제5장 과오금, 피해보상 등
          </h2>
        </div>
        <p />
        <strong>제24조 (과오금)</strong>
        <p />
        <div>
          <p />
          1. 회사는 과오금이 발생한 경우 이용대금의 결제와 동일한 방법으로
          과오금 전액을 환불하여야 합니다. 다만, 동일한 방법으로 환불이 불가능
          할 때는 이를 사전에 고지합니다.
          <p />
          2. 회사의 책임 있는 사유로 과오금이 발생한 경우 회사는 계약비용,
          수수료 등에 관계없이 과오금 전액을 환불합니다. 다만, 회원의 책임있는
          사유로 과오금이 발생한 경우, 회사가 과오금을 환불하는 데 소요되는
          비용은 합리적인 범위 내에서 회원이 부담하여야 합니다.
          <p />
          3. 회사는 회원이 주장하는 과오금에 대해 환불을 거부할 경우에 정당하게
          이용대금이 부과되었음을 입증할 책임을 집니다.
          <p />
          4. 회사는 과오금의 환불절차를 디지털콘텐츠 이용자보호지침에 따라
          처리합니다.
        </div>
        <p />
        <strong>제25조 (콘텐츠하자 등에 의한 이용자 피해보상)</strong>
        <p />
        회사는 콘텐츠 하자 등에 의한 이용자 피해보상의
        기준&middot;범위&middot;방법 및 절차에 관한 사항을 디지털콘텐츠
        이용자보호지침에 따라 처리합니다.
        <br />
        <br />
        <strong>제26조 (면책사유)</strong>
        <p />
        <div>
          <p />
          1. 회사는 천재지변 또는 이에 준하는 불가항력으로 인하여 서비스를
          제공할 수 없는 경우에 회원에게 발생한 손해에 대하여 고의 및 중대한
          과실이 없는 한 책임을 지지 않습니다.
          <p />
          2. 회사는 기간통신 사업자가 전기통신 서비스를 중지하거나 정상적으로
          제공하지 아니하여 손해가 발생한 경우 책임이 면제됩니다.
          <p />
          3. 회사는 서비스용 설비의 보수, 교체, 정기점검, 공사 등 부득이한
          사유로 발생한 손해에 대하여 회사의 고의 또는 과실이 없는 한 책임이
          면제됩니다.
          <p />
          4. 회사는 회원 또는 제3자가 게시판에 게재한 정보, 자료, 사실의 신뢰도,
          정확성 등의 내용에 대하여 책임을 지지 않습니다.
          <p />
          5. 회사는 회원 상호 간 또는 회원과 제3자 간에 서비스를 매개로 하여
          발생한 분쟁 등에 대하여 책임을 지지 않습니다.
          <p />
          6. 회사는 회원의 귀책사유로 인한 콘텐츠이용의 장애에 대하여는 책임을
          지지 않습니다.
        </div>
        <p />
        <strong>제27조 (분쟁의 해결)</strong>
        <p />
        <p>
          회사는 분쟁이 발생하였을 경우에 이용자가 제기하는 정당한 의견이나
          불만을 반영하여 적절하고 신속한 조치를 취합니다. 다만, 신속한 처리가
          곤란한 경우에는 회사는 회원에게 그 사유와 처리일정을 통보합니다.
        </p>
        <p />
        <p />
        <strong>제28조 (재판권 및 준거법)</strong>
        <p />
        회사와 이용자 사이에 발생한 분쟁에 관한 소송은 대한민국의 민사소송법상의
        관할법원에서 제기하는 것을 원칙으로 합니다.
        <p />
        회사와 이용자간에 제기된 분쟁에 관한 소송에는 대한민국 법을 적용합니다.
        <p />본 약관은&nbsp;2024년 01월 16일부터 적용됩니다.
        <p />
        <p />
      </div>
    </div>
  );
}

export default TermsKR;
