import React, { useRef, useState, useEffect, useCallback } from 'react';

import './Main.scss';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
// import { useScrollFadeIn } from '../../hooks/useScrollFadeIn';

function Main() {
  const moodDiaryRef = useRef(null);
  const aiTherapistRef = useRef(null);
  const selfAssessmentRef = useRef(null);
  const [showTopButton, setShowTopButton] = useState(false);

  const scrollToSection = (sectionRef, options = {}) => {
    if (sectionRef && sectionRef.current) {
      const { yOffset = 0, behavior = 'smooth' } = options;
      const y =
        sectionRef.current.getBoundingClientRect().top +
        window.pageYOffset +
        yOffset;
      window.scrollTo({ top: y, behavior: behavior });
    }
  };

  const getImageSrc = (imageName, isMobile) => {
    const suffix = isMobile ? '_m' : '';
    return `/images/${imageName}${suffix}.svg`;
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setShowTopButton(true);
      } else {
        setShowTopButton(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const isMobile = window.innerWidth <= 768;

  // This part could be hooks but due to some unknown reason, it's here for now.
  const useScrollFadeIn = (
    direction = 'up',
    duration = 1,
    delay = 0,
    offset = 1000,
  ) => {
    const dom = useRef();

    const handleDirection = name => {
      switch (name) {
        case 'up':
          return 'translate3d(0, 50%, 0)';
        case 'down':
          return 'translate3d(0, -50%, 0)';
        case 'left':
          return 'translate3d(50%, 0, 0)';
        case 'right':
          return 'translate3d(-50%, 0, 0)';
        default:
          return;
      }
    };

    const handleScroll = useCallback(
      ([entry]) => {
        const { current } = dom;
        if (entry.isIntersecting) {
          current.style.transitionProperty = 'all';
          current.style.transitionDuration = `${duration}s`;
          current.style.transitionTimingFunction = 'cubic-bezier(0, 0, 0.2, 1)';
          current.style.transitionDelay = `${delay}s`;
          current.style.opacity = 1;
          current.style.transform = 'translate3d(0, 0, 0)';
        }
      },
      [delay, duration],
    );

    useEffect(() => {
      let observer;
      const { current } = dom;

      if (current) {
        observer = new IntersectionObserver(handleScroll, {
          threshold: 0.7,
          rootMargin: `0px 0px 1000px 0px`,
        });
        observer.observe(current);
      }

      return () => observer && observer.disconnect();
    }, [handleScroll, offset]);

    return {
      ref: dom,
      style: {
        opacity: 0,
        transform: handleDirection(direction),
      },
    };
  };

  const topFadeIn = useScrollFadeIn('up', 1, 0.5);
  const CBTFadeIn = useScrollFadeIn('up', 1, 0.5);
  const moodDiaryFadeIn = useScrollFadeIn('up', 1, 0.5);
  const personalizedFadeIn = useScrollFadeIn('up', 1, 0.5);
  const aiTherapistFadeIn = useScrollFadeIn('up', 1, 0.5);
  const selfAssessmentFadeIn = useScrollFadeIn('up', 1, 0.5);
  const bottomFadeIn = useScrollFadeIn('up', 1, 0.5);

  return (
    <div className="main">
      <Header
        onMenuItemClick={section => {
          switch (section) {
            case 'Mood Diary':
              scrollToSection(moodDiaryRef);
              break;
            case 'AI Therapist':
              scrollToSection(aiTherapistRef);
              break;
            case 'Self-Assessment':
              scrollToSection(selfAssessmentRef, { yOffset: -170 });
              break;
            default:
              break;
          }
        }}
      />
      <div className="one">
        <div className="image"></div>
        <div className="context" ref={topFadeIn.ref} style={topFadeIn.style}>
          <img src={getImageSrc('main_1_logo', isMobile)} alt="Logo" />
          <h1>alive</h1>
          <span>The best way to stay close to yourself</span>
          <div className="downloads-top ">
            <a className="apple" href="https://apps.apple.com/app/id6473222826">
              <img src={getImageSrc('apple_top', isMobile)} alt="Apple" />
            </a>
            <a
              className="google"
              href="https://play.google.com/store/apps/details?id=com.akacognitive.alive"
            >
              <img src={getImageSrc('google_top', isMobile)} alt="Google" />
            </a>
          </div>
        </div>
      </div>

      <div className="two">
        <div
          className="ref-wrapper"
          ref={CBTFadeIn.ref}
          style={CBTFadeIn.style}
        >
          <div>
            <img src="/images/main_2_char1.svg"></img>
            <h3>24/7 mental health support</h3>
            <span className="ai-counselor">
              AI therapy sessions accessible whenever, wherever
            </span>
          </div>
          <div>
            <img src="/images/main_2_char2.svg"></img>
            <h3>CBT-based approaches</h3>
            <span>
              Commonly applied approaches
              <br /> in mental healthcare
            </span>
          </div>
          <div>
            <img src="/images/main_2_char3.svg"></img>
            <h3 className="text">Data-driven insights </h3>
            <span className="text">
              For increased self-awareness and better understanding of your
              mental health
            </span>
          </div>
        </div>
      </div>
      <div className="three" ref={moodDiaryRef}>
        <div
          className="body"
          ref={moodDiaryFadeIn.ref}
          style={moodDiaryFadeIn.style}
        >
          <div className="context">
            <h3>Mood Diary</h3>
            <h2>Stay in touch with your emotions</h2>
            <span>
              Tracking your mental changes is the first step to self-awareness
            </span>
          </div>
          <img src={getImageSrc('main_3', isMobile)} alt="Mood Diary" />
        </div>
      </div>
      <div className="four">
        <div
          className="body"
          ref={personalizedFadeIn.ref}
          style={personalizedFadeIn.style}
        >
          <img src={getImageSrc('main_4', isMobile)} alt="Image 4" />
          <div className="context">
            <h2>1:1 personalized analysis</h2>
            <span>
              Get to know yourself better by exploring personalized data
              including frequently used words and factors that affect your mood
            </span>
          </div>
        </div>
      </div>
      <div className="five" ref={aiTherapistRef}>
        <div className="image">
          <div
            className="body top"
            ref={aiTherapistFadeIn.ref}
            style={aiTherapistFadeIn.style}
          >
            <div className="context">
              <h3>AI counselor</h3>
              <h2>Communicate your feelings </h2>
              <span>
                Build positive, healthy thinking patterns by talking to Alive, a
                CBT-based, top-of-the-line AI counselor – anytime and anywhere,
                just for you
              </span>
            </div>
            <img src={getImageSrc('main_5', isMobile)} alt="AI Counselor" />
          </div>
          <div ref={selfAssessmentRef}></div>
          <div
            className="body bottom"
            ref={selfAssessmentFadeIn.ref}
            style={selfAssessmentFadeIn.style}
          >
            <img src={getImageSrc('main_6', isMobile)} alt="Self-Assessment" />
            <div className="context">
              <h3>Self-assessment </h3>
              <h2>Discover yourself</h2>
              <span>
                Check your mental state through a simple self-assessment at your
                convenience, unrestricted by time or place
              </span>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="six">
        <div className="context">
          <h3>Even more</h3>
          <h2>
            Connection and close management from expert medical professionals
          </h2>
          <span>
            Access personalized care from medical experts swiftly and
            effortlessly through a QR code
          </span> */}
      {/* Disabled the button for now */}
      {/* <div className="more">자세히 알아보기</div>
        </div>
        <img src={getImageSrc('main_7', isMobile)} alt="Doctor Image"></img>
      </div> */}
      <div className="seven">
        <div className="image">
          <div
            className="ref-wrapper"
            ref={bottomFadeIn.ref}
            style={bottomFadeIn.style}
          >
            <h2>
              Get alive,
              <br /> Your personal AI counselor.
            </h2>
            <div className="downloads-bottom">
              <a
                className="apple"
                href="https://apps.apple.com/app/id6473222826"
              >
                <img src={getImageSrc('apple_top', isMobile)} alt="Apple" />
              </a>
              <a
                className="google"
                href="https://play.google.com/store/apps/details?id=com.akacognitive.alive"
              >
                <img src={getImageSrc('google_top', isMobile)} alt="Google" />
              </a>
            </div>
          </div>
        </div>
      </div>
      {showTopButton && (
        <div
          className="top-button"
          onClick={() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
        >
          <img src="/images/top_button.svg" alt="Top Button" />
        </div>
      )}
      <Footer />
    </div>
  );
}

export default Main;
