import React from 'react';
import './Privacy.scss';

function Privacy() {
  return (
    <div className="terms">
      <h3 style={{ textAlign: 'center' }}>Privacy Policy</h3>
      <p>
        <strong>1. General</strong>
      </p>
      <p>
        AKA (&ldquo;<strong>AKA</strong>,&rdquo; &ldquo;<strong>Company</strong>
        ,&rdquo; or &ldquo;<strong>we</strong>&rdquo;) provides this Privacy
        Policy (&ldquo;<strong>Policy</strong>&rdquo; or &ldquo;
        <strong>Privacy Policy</strong>&rdquo;) to describe the information we
        collect, how we use it, and when and with whom we share it. This Policy
        applies to Alive (&ldquo;<strong>Application</strong>,&rdquo;
        collectively with the Website, &ldquo;<strong>Service</strong>&rdquo;).
      </p>
      <p>
        By accessing or using the Service, you agree to this Privacy Policy. If
        you do not agree to this Policy, please do not access or use the
        Service. AKA is the data controller for your information. Capitalized
        terms not defined here have the meanings set forth in the terms and
        conditions (the &ldquo;<strong>Terms and Conditions</strong>&rdquo;),
        located at Terms and Conditions.
      </p>
      <p>
        We may update our Privacy Policy to reflect changes to our information
        practices. If we do this and the changes are material, we will send a
        notification that we have made changes to this Privacy Policy in the
        Application for at least 7 days before the changes are made, and we will
        indicate the date these terms were last revised at the bottom of the
        Privacy Policy. Any revisions to this Privacy Policy will become
        effective at the end of that 7-day period.
      </p>
      <p>
        <strong>2. The Information We Collect</strong>
      </p>
      <p>
        We collect information about you by providing our Service. The types of
        information we collect depend on the Service and the features you use on
        the Service. Please note that we need certain types of information to
        provide the Service to you. If you do not provide us with such
        information, or if you ask us to delete that information, you may no
        longer be able to access or use certain Services.
      </p>
      <p>
        <em>i. Information you provide.</em>
      </p>
      <p>We may collect information that you provide directly to us through:</p>
      <p>
        ● Your use of our Service, e.g. when you register for an account or use
        the services we offer
      </p>
      <p>
        ● Your request for information about our Service, such as when inquiring
        about our services
      </p>
      <p>● Your purchase or use of our Service</p>
      <p>● Your subscription to newsletters</p>
      <p>● Your registration for promotions</p>
      <p>● Your requests for support and/or technical assistance</p>
      <p>● Your transmission of user content or other information to us</p>
      <p>● Any other communications you have with us.</p>
      <p>
        The information you provide directly to us may concern you or others and
        may include, but is not limited to:
      </p>
      <p>
        ● <strong>Account Registration and Contact Information</strong>. We may
        collect information from you when you sign up for an account with us.
        That information may include, among other things, your name, username,
        email address, profile picture, nickname, password, date of birth,
        grade, address, gender, and other information you provide.
      </p>
      <p>
        ● <strong>Payment and Financial Information.</strong> We or our service
        providers may collect payment information from you, such as payment card
        number, expiration date, billing address, and payment account name.
      </p>
      <p>
        ● <strong>Demographic Information.</strong> We may collect your
        demographic information such as your country of residence, time zone,
        and language(s) spoken.
      </p>
      <p>
        ●{' '}
        <strong>Other Content and Other Information You Provide to Us.</strong>{' '}
        We collect additional content and information that you voluntarily
        provide us, such as content you submit when you respond to surveys or
        contact us via the Service or by email.
      </p>
      <p>
        You are not required to provide us with such information, but certain
        features of the Service may not be accessible or available, absent the
        provision of the requested information.
      </p>
      <p>
        <em>ii. Activity Data.</em>
      </p>
      <p>
        When you use the Service, you will submit information and content to
        your profile. We will also generate data about your use of our Service
        including your engagement in educational activities on the Service
        (&ldquo;<strong>Activity Data</strong>&rdquo;). This provides us with a
        set of data and the history of your activities on the Application. We
        capture and analyze your usage data to help us make your experience
        better.
      </p>
      <p>
        We also collect the following technical data about how you interact with
        our Service:
      </p>
      <p>
        <strong>Learning Activity, Navigation, Tech Specs:</strong>
      </p>
      <p>● Pages visited</p>
      <p>● Session progress</p>
      <p>● Answers</p>
      <p>● Session duration</p>
      <p>● Device type</p>
      <p>● Operating system</p>
      <p>● Script errors</p>
      <p>● IP address</p>
      <p>
        <em>iii. Voice Recognition.</em>
      </p>
      <p>
        Some sessions involve you speaking about the Application. When you use
        AI Chat, we will use your device's microphone throughout the session for
        voice recognition. To recognize speech, your audio may be sent to a
        third-party provider such as Google, Apple, or Amazon Web Services. We
        may ask you to allow AKA to collect and analyze your speech data to help
        us understand the effectiveness of our sessions and to improve the
        product. Any audio you agree to send to AKA is anonymized when it
        reaches our servers to ensure that no trace of your personal data
        remains attached to the audio.
      </p>
      <p>
        <em>iv. Device/Usage Information.</em>
      </p>
      <p>
        We and our third-party service providers, which include ad networks and
        analytics companies such as Google Analytics, may use cookies, pixel
        tags, web beacons, and other tracking technologies to collect
        information about the devices you use to access the Service. As
        described further below, we may collect and analyze information
        including but not limited to:
      </p>
      <p>● The type of device that you use</p>
      <p>● ISP or operating system</p>
      <p>● Domain name</p>
      <p>● Access time</p>
      <p>● Referring to or exit pages</p>
      <p>● Page views</p>
      <p>● IP address</p>
      <p>● Unique device identifiers (e.g. IDFA or Android ID)</p>
      <p>
        We may also track when and how frequently you access or use the Service,
        including how you engage with or navigate our mobile application. We use
        this information (including the information collected by our third-party
        service providers) for analytics (including to determine which portions
        of the Service are used most frequently and what our users like/do not
        like), to assist in determining relevant advertising (both on and off
        the Service), to evaluate the success of our advertising campaigns, and
        as otherwise described in this Privacy Policy.
      </p>
      <p>
        <em>v. Cookies and Other Electronic Technologies.</em>
      </p>
      <p>
        We and our third-party service providers may use cookies, clear GIFs,
        and other technologies that help us better understand user behavior,
        personalize preferences, perform research and analytics, and improve the
        Service. These technologies, for example, may allow us to tailor the
        Service to your needs, save your password in password-protected areas,
        track the sessions you engage in, help us manage content, and compile
        statistics about usage of our Service. We or our third-party service
        providers also may use certain of these technologies in emails to our
        customers to help us track email response rates, identify when our
        emails are viewed, and track whether our emails are forwarded.
      </p>
      <p>
        <em>vi. Location Information.</em>
      </p>
      <p>
        When you use the Service, we may collect general location information
        (such as general location inferred from an IP address).
      </p>
      <p>
        <strong>3. Cookies</strong>
      </p>
      <p>
        When you use our mobile Application, we collect your device type, your
        device ID, and your IP address (a unique address that identifies your
        device on the Internet).
      </p>
      <p>
        In addition, we store certain information from your device using "
        <strong>cookies</strong>.&rdquo; A cookie is a piece of data stored on
        the user's device tied to information about the user. We use session ID
        cookies to confirm that users are logged in. Cookies may be necessary to
        provide you with certain features available on the Application (e.g.,
        customized delivery of information).
      </p>
      <p>
        <strong>4. Use of Information We Collect</strong>
      </p>
      <p>
        We use your information for business and commercial purposes, such as:
      </p>
      <p>
        ● To provide you with the Services, including registering you for an
        account, enabling sessions, and processing transactions
      </p>
      <p>
        ● To respond to your questions or inquiries, including to provide
        support and troubleshooting assistance
      </p>
      <p>
        ● To communicate with you, including to communicate with you about your
        account, inquiries, our company, and related news, as well as to send
        you promotional emails, surveys, newsletters, or notifications
      </p>
      <p>
        ● To manage and remember your preferences and customize the Services
      </p>
      <p>
        ● To analyze and improve the Services or any other products and services
        we provide
      </p>
      <p>● To administer surveys, sweepstakes, or promotions</p>
      <p>● To verify your identity</p>
      <p>● To comply with our legal obligations or as permitted by law</p>
      <p>
        ● To protect the safety and/or integrity of our users, employees, third
        parties, members of the public, and/or our Services
      </p>
      <p>● To prevent fraud and enforce our legal terms</p>
      <p>● To administer and troubleshoot the Services.</p>
      <p>
        AKA may use or share anonymous data collected through the Service,
        including Activity Data without limitation. As a business, it is
        critical that we perform our contract with you with the best service
        possible, and it is in our legitimate interests to perform these
        processing functions and to enable service notifications by default to
        keep your data secure and provide our Service. You may opt out of any
        non-essential service notifications at any time.
      </p>
      <p>
        <strong>5. Sharing Your Personal Data with Third Parties</strong>
      </p>
      <p>
        AKA shares your personal data only when it is necessary to offer the
        Service, legally required, or permitted by you. We may share or disclose
        your personal information for the following reasons:
      </p>
      <p>
        ● <strong>Affiliates</strong> We may share your information with AKA
        affiliates.
      </p>
      <p>
        ● <strong>Consent/At Your Direction.</strong> We may disclose your
        information to nonaffiliated third parties based on your consent to do
        so. Such consent includes the disclosure of your information as
        described in this Privacy Policy, the User Agreement, or any other legal
        terms governing your use of the Services.
      </p>
      <p>
        ● <strong>Service Providers</strong>. We provide access to or share your
        information with select vendors who perform services on our behalf. They
        provide a variety of services to us, including data storage, analytics,
        billing, marketing, product content and features, customer service, data
        storage, security, fraud prevention, and legal services.
      </p>
      <p>
        ● <strong>Protection of AKA and Others</strong>. We may share or
        disclose certain information if we believe in good faith that doing so
        is necessary or appropriate to (i) protect or defend the rights, safety,
        or property of AKA or third parties, including to defend or enforce our
        Privacy Policy, our User Agreement, or any other contractual
        arrangement; (ii) respond to your requests for customer service; and/or
        (iii) protect the rights, property or personal safety of AKA, its agents
        and affiliates, its employees, users and/or the public.
      </p>
      <p>
        ● <strong>Legal Requirements.</strong> We may share or disclose certain
        information if we believe in good faith that doing so is necessary or
        appropriate to comply with any law enforcement, legal, or regulatory
        process, such as responding to a warrant, subpoena, court order, or
        other applicable laws and regulations.
      </p>
      <p>
        ● <strong>Business Transfer.</strong> We may share or disclose certain
        information, in connection with or during negotiations of any merger,
        sale of company assets, financing, or acquisition of all or a portion of
        our business to another company.
      </p>
      <p>
        ● <strong>Aggregate/Anonymous Information.</strong> From time to time,
        we may share aggregate/anonymous information about the use of the
        Services, such as by creating reports on usage trends. The sharing of
        such data is unrestricted.
      </p>
      <p>
        <strong>6. Data Subject Rights and Data Retention</strong>
      </p>
      <p>
        You can manage your account settings at &lsquo;Profile&rsquo; to update,
        amend, and correct your information.
      </p>
      <p>
        You also have the following rights in relation to the personal data we
        hold about you unless provided otherwise by local law:
      </p>
      <p>
        ● To request access to or erasure of, the personal data we hold about
        you.
      </p>
      <p>
        ● To request us to restrict the processing of the personal data we hold
        about you.
      </p>
      <p>● To object to us processing personal data relating to you.</p>
      <p>
        ● Where you have given us consent to process your personal data, you
        have the right to withdraw that consent at any time.
      </p>
      <p>
        ● To export the personal data you have provided to AKA in a format that
        can be transferred electronically to a third party.
      </p>
      <p>
        ● To delete your account with AKA by following the instructions
        available through the Service.
      </p>
      <p>
        AKA will retain your data until your account is deleted, after which
        point we will retain anonymous data collected through the Service which
        may be used by AKA and shared with third parties in any manner.
        Information associated with voice recognition, including audio
        recordings of your speech, will be collectively deleted from your AKA
        account after five (5) years have passed from the date of collection,
        but anonymized data will be kept indefinitely by AKA to improve the
        examination and for research and analysis.
      </p>
      <p>
        Please note that some of these rights are not absolute. In some cases,
        we may refuse a request to exercise particular rights if complying with
        it means that we are no longer able to meet our contractual obligation
        to provide you with particular products and services. We will keep you
        informed as to the actions that we can take when you make your request.
      </p>
      <p>
        <strong>7. Children Under Age of Digital Consent</strong>
      </p>
      <p>
        We refer to users under the age of 13 in the United States and 16 in all
        other jurisdictions as &ldquo;<strong>child users</strong>&rdquo;. Child
        users are permitted to create a user account and we may collect personal
        information directly from child users.
      </p>
      <p>
        After registration, we will send an email notifying the parent about
        AKA&rsquo;s privacy and information practices regarding child users,
        including what personal data we collect and how we use, share, and
        protect that personal data. The email also explains how parents can
        request that AKA access, change, or delete the personal data about their
        child.
      </p>
      <p>
        <strong>8. Information Security</strong>
      </p>
      <p>
        AKA has implemented administrative and technical safeguards we believe
        are appropriate to protect the confidentiality, integrity, and
        availability of your personal data. However, given sufficient resources,
        a determined attacker could defeat those safeguards and may, as a
        result, gain access to the data we seek to protect. Consequently, we
        cannot ensure or warrant the security of any information you transmit to
        us.
      </p>
      <p>
        <strong>9. Our Legal Bases for Use of Your Information</strong>
      </p>
      <p>
        The laws of some jurisdictions such as the laws of the European Union
        require data controllers to tell you about the legal ground that they
        rely on for using, sharing, or disclosing your information. To the
        extent those laws apply, our legal grounds are as follows:
      </p>
      <p>
        ● <strong>Contractual Commitments</strong> We may use, share, or
        disclose information to honor our contractual commitments to you. For
        example, to comply with our User Agreement, which you accept by browsing
        or otherwise using our Services.
      </p>
      <p>
        ● <strong>With Your Consent.</strong> Where required by law, and in some
        other cases, we use, share, or disclose information on the basis of your
        consent.
      </p>
      <p>
        ● <strong>Legitimate Interests</strong>. In many cases, we use, share,
        or disclose information on the grounds that it furthers our legitimate
        business interests in ways that are not overridden by the interests or
        fundamental rights and freedoms of the affected individuals, such as
        customer service, certain promotional activities, analyzing and
        improving our business, providing security for our Services, preventing
        fraud, and managing legal issues.
      </p>
      <p>
        ● <strong>Legal Compliance.</strong> We need to use, share, and disclose
        information in certain ways to comply with our legal obligations.
      </p>
      <p>
        <strong>10. Consent to Transfer</strong>
      </p>
      <p>
        Our systems are currently based in the Republic of Korea, so your
        personal information will be processed by us in the Republic of Korea,
        where data protection and privacy regulations may not offer the same
        level of protection as in other parts of the world. By using the
        Services, you agree to this Privacy Policy and you consent to the
        transfer of all such information to the Republic of Korea, which may not
        offer a level of protection equivalent to that required in certain other
        countries, and to the processing of that information as described in
        this Privacy Policy.
      </p>
      <p>
        <strong>11. Contact Us</strong>
      </p>
      <p>
        AKA&rsquo;s headquarters are located within the Republic of Korea at:
      </p>
      <p>AKA</p>
      <p>
        9th Floor, 100, Cheonggyecheon-ro, Jung-gu, Seoul, Republic of Korea
      </p>
      <p>04542</p>
      <p>
        For all support inquiries, data privacy inquiries, and any questions or
        concerns you have about this Privacy Policy, please contact
        <span style={{ color: '#0000ff' }}> info@akaintelligence.com</span>.
      </p>
      <p>Last revised on Nov 17, 2023</p>
    </div>
  );
}

export default Privacy;
