import React from 'react';
import './Privacy.scss';

function PrivacyKR() {
  return (
    <div className="terms">
      <h3 style={{ textAlign: 'center' }}>Alive 개인정보 처리방침</h3>
      <p>
        <strong>일반</strong>
      </p>
      <p>
        "(주) 아카에이아이" (이하 “회사” 또는 “아카에이아이”라고 합니다)는
        「개인정보보호법」,「정보통신망 이용촉진 및 정보보호 등에 관한
        법률」상의 개인정보보호규정 및 정보통신부가 제정한
        「개인정보보호지침」을 준수하고 있습니다. 개인정보보호정책을 통해
        이용자가 제공하는 개인정보가 어떠한 용도와 방식으로 이용되며
        개인정보보호를 위해 어떠한 조치가 취해지고 있는지 알려드립니다. 이용자는
        개인정보의 수집, 이용, 제공, 위탁과 관련하여 원하지 않는 경우 동의를
        거부할 수 있습니다. 다만, 이용자가 동의를 거부하는 경우 서비스의 전부
        또는 일부를 이용할 수 없음을 알려드립니다.
      </p>
      <p>
        <strong>제1조 수집하는 개인정보 항목 및 수집방법</strong>
      </p>
      <p>회사는 다음의 개인정보 항목을 처리하고 있습니다.</p>
      <p>1.수집하는 개인정보 항목</p>
      <p>
        <em>
          1) 회사는 회원가입 및 로그인, 원활한 고객상담, 각종 최적화된 서비스를
          제공하기 위하여, 다음과 같은 최소한의 개인정보를 이용자의 회원가입 시
          수집하고 있습니다.
        </em>
      </p>
      <p>● 일반 회원가입 시 필수항목 : 이메일, 패스워드, 성별, 닉네임</p>
      <p>
        ● 소셜 네트워크 계정으로 회원가입 시 필수항목 : 이메일, 성별, 닉네임
      </p>
      <p>
        <em>
          2) 회사는 원활한 고객상담 및 각종 서비스의 제공을 위해 아래와 같은
          추가적인 정보를 수집할 수 있습니다.
        </em>
      </p>
      <p>● 일반 회원가입 시 필수항목 : 이메일, 패스워드, 성별, 닉네임</p>
      <p>
        ● 이름, 연락 가능 번호, 상담가능시간, 상담내용, 가입경로, 메일 수신
        여부, 구독 정보 및 이벤트 소식 푸시알림 수신 여부
      </p>
      <p>
        <em>
          3) 서비스 이용과정이나 사업처리 과정에서 아래와 같은 정보들이 자동으로
          생성, 수집될 수 있습니다.
        </em>
      </p>
      <p>
        ● 모바일 서비스 이용 시 단말기에 관한 정보(단말기 모델, 하드웨어 ID,
        운영체제 버전정보), 접속 IP 정보, 브라우저 유형, 사용된 언어, 방문일자와
        시간, 소프트웨어와 하드웨어의 특징정보 및 접속로그 등의 데이터
      </p>
      <p>
        <em>
          4) 유료 서비스 이용 과정에서 아래와 같은 결제 정보가 수집될 수
          있습니다.
        </em>
      </p>
      <p>● 신용카드 결제 시 필수항목: 카드사명, 카드번호 등</p>
      <p>● 인앱 결제 시 필수항목: 영수증 번호</p>
      <p>● 간편 결제 시 필수항목 : 전화번호</p>
      <p>2.개인정보 수집방법</p>
      <p>회사는 다음과 같은 방법으로 개인정보를 수집합니다.</p>
      <p>● 홈페이지, 모바일 애플리케이션(앱), 전화, 이메일, 이벤트 응모 등</p>
      <p>
        <strong>제2조 개인정보 이용목적</strong>
      </p>
      <p>회사는 다음의 개인정보 항목을 처리하고 있습니다.</p>
      <p>
        1.서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 요금 정산 콘텐츠
        제공, 맞춤 서비스 제공, 본인인증, 구매 및 요금 결제 또는 청구서 등 발송,
        요금 추심 등
      </p>
      <p>
        2.회원 관리, 회원제 서비스 이용 및 제한적 본인 확인제에 따른 본인 확인,
        불량회원(아카이에이아이 이용약관 제 16조 위반 사유로 인하여 영구이용이
        정지된 회원)의 부정 이용방지와 비인가 사용방지, 가입 의사 확인, 가입 및
        가입 횟수 제한, 분쟁 조정을 위한 기록 보존, 민원처리, 공지사항 전달
      </p>
      <p>
        3. 신규 서비스 개발, 통계학적 특성, 이용 형태 등에 따른 맞춤형 서비스
        제공, 광고 게재, 이벤트 참여기회 제공, 접속빈도 파악, 회원의 서비스
        이용에 대한 통계
      </p>
      <p>4. 서비스 이용에 대한 통계, 서비스의 유효성 확인, 접속 빈도 파악</p>
      <p>
        <strong>제3조 개인정보의 제공 및 공유</strong>
      </p>
      <p>
        회사는 이용자들의 개인정보를 위의 [1.수집하는 개인정보 항목 및
        수집방법],[2.개인정보 이용목적]에서 고지한 범위 내에서 사용하며,
        이용자의 사전 동의 없이는 동 범위를 초과하여 이용하거나 원칙적으로
        이용자의 개인정보를 외부에 공개하지 않습니다.
      </p>
      <p>
        <em>다만, 아래의 경우는 예외로 합니다.</em>
      </p>
      <p>● 회원이 사전에 공개에 동의한 경우</p>
      <p>● 제 3자와 회사가 단독 혹은 공통적인 서비스를 제공하는 경우</p>
      <p>
        ● 법령의 규정에 따르거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라
        수사기관의 요구가 있는 경우
      </p>
      <p>● 서비스 제공에 따른 요금 정산을 위하여 필요한 경우</p>
      <p>
        ● 통계작성, 학술연구 또는 시장조사를 위하여 필요한 경우로서 특정 개인을
        식별할 수 없는 형태로 제공하는 경우
      </p>
      <p>
        이 경우, 서비스가 끝난 후에는 관련 정보들에 대한 재열람이 엄격히 금지될
        것입니다.
      </p>
      <p>
        <strong>제4조 개인정보의 취급위탁</strong>
      </p>
      <p>
        회사는 원활하고 편리한 서비스를 위하여, 최소한의 제한된 범위에서
        개인정보를 외부 전문업체에 위탁하여 운영하고 있습니다. 위탁 계약 시
        개인정보가 안전하게 관리될 수 있도록 관련 사항들을 별도로 규정하고
        있습니다.
      </p>
      <p>
        <em>1. 국내업체</em>
      </p>
      <p>
        ● 콘텐츠에 대한 구매 대금은 원칙적으로 카드회사와 앱에서 정하는 방법,
        정책 등에 따라 부과되며 납부 방법 역시 정해진 방법에 따라 납부합니다.
        회사의 정책 및 결제업체(카드회사, 애플리케이션 스토어 등)의 정책, 정부의
        방침 등에 따라 결제 한도가 부여될 수 있습니다.
      </p>
      <p>
        <strong>제5조 개인정보의 보유 및 이용 기간</strong>
      </p>
      <p>
        회원 탈퇴를 요청하거나 개인정보의 수집 및 이용에 대한 동의를 철회하는
        경우, 수집 및 이용 목적이 달성되거나 보유 및 이용 기간이 종료한 경우
        해당 개인정보를 지체 없이 파기합니다. 단, 다음의 정보에 대해서는 관련
        법령에 따라 명시한 기간에 별도로 분리 보관되며, 명시한 기간이 경과한
        개인정보는 지체없이 파기합니다.
      </p>
      <p>
        <em>1. 회사 내부 방침에 의한 정보보유 사유</em>
        <p>회원 탈퇴 시 보존하는 개인 정보:</p>
      </p>
      <p>● 보존 항목: 이름, 이메일 주소, 연락처, 주소</p>
      <p>● 보존 근거: 명예훼손 등 권리침해 분쟁 및 수사협조</p>
      <p>● 보존 기간: 회원 탈퇴 후 1년</p>
      <p>
        <em>2.관련 법령에 의한 정보보유 사유</em>
      </p>
      <p>계약 또는 청약철회 등에 관한 기록</p>
      <p>● 보존 근거 : 전자상거래 등에서의 소비자보호에 관한 법률</p>
      <p>● 보존 기간 : 5년</p>
      <p>대금결제 및 재화 등의 공급에 관한 기록</p>
      <p>● 보존 근거 : 전자상거래 등에서의 소비자보호에 관한 법률</p>
      <p>● 보존 기간 : 5년</p>
      <p>소비자의 불만 또는 분쟁처리에 관한 기록</p>
      <p>● 보존 근거 : 전자상거래 등에서의 소비자보호에 관한 법률</p>
      <p>● 보존 기간 : 3년</p>
      <p>
        <strong>제6조 개인정보 파기절차 및 방법</strong>
      </p>
      <p>
        개인정보는 원칙적으로 개인정보의 수집 및 이용목적이 달성되면 지체 없이
        파기합니다. 회사의 개인정보 파기절차 및 방법은 다음과 같습니다.
      </p>
      <p>
        <em>1. 파기절차</em>
      </p>
      <p>
        ● 이용자가 회원가입 등을 위해 입력한 정보는 목적이 달성된 후 내부 방침
        및 기타 관련 법령에 의한 정보 보호 사유에 따라(보유 및 이용 기간 참조)
        일정 기간 저장된 후 파기됩니다.
      </p>
      <p>
        ● 동 개인정보는 법률에 의한 경우가 아니고서는 보유되는 이외의 다른
        목적으로 이용되지 않습니다.
      </p>
      <p>
        <em>2. 파기방법</em>
      </p>
      <p>
        ● 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.
      </p>
      <p>
        ● 전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적
        방법을 사용하여 삭제합니다.
      </p>
      <p>
        <strong>제7조 이용자의 권리와 의무</strong>
      </p>
      <p>
        <em>1. 이용자의 권리</em>
      </p>
      <p>
        ● 회원은 언제든지 등록된 자신의 개인정보를 조회하거나 수정할 수 있으며
        가입 해지를 요청할 수도 있습니다.
      </p>
      <p>
        ● 개인정보 조회, 수정을 위해서는 ‘내 정보 변경'을, 가입해지(동의 철회)를
        위해서는 ‘회원탈퇴'를 클릭하여 본인 확인 절차를 거친 후 열람/수정/탈퇴가
        가능합니다.
      </p>
      <p>
        ● 개인정보 보호책임자에게 서면, 전화 또는 이메일로 연락하시면 지체 없이
        조치하겠습니다.
      </p>
      <p>
        ● 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기 전까지
        당해 개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된 개인정보를 제3
        자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체없이 통지하여
        정정이 이루어지도록 하겠습니다.
      </p>
      <p>
        <em>2. 이용자의 의무</em>
      </p>
      <p>
        ● 회원은 본인의 개인정보를 최신의 상태로 정확하게 입력하여 불의의 사고를
        예방해주시기 바랍니다. 이용자가 부정확하게 입력한 정보로 발생하는 사고의
        책임은 이용자 자신에게 있으며 타인의 정보를 도용하거나 허위정보를 입력할
        경우 계정의 이용이 제한되거나 회원자격이 상실될 수 있습니다.
      </p>
      <p>
        ● 회원은 스스로를 보호하고 타인의 정보를 침해하지 않을 의무를 가지고
        있습니다. 비밀번호를 포함한 본인의 개인정보가 유출되지 않도록 주의하시고
        타인의 개인정보와 게시물을 훼손하지 않도록 유의해 주십시오.
      </p>
      <p>
        ● 이 같은 책임을 다하지 못하고 타인의 정보 및 존엄성을 훼손할 시,
        ‘정보통신망이용촉진 및 정보 보호등에관한법률’ 등에 의해 처벌받을 수
        있습니다.
      </p>
      <p>
        <strong>제8조 개인정보의 기술적/관리적 보호 대책</strong>
        <p>
          회사는 이용자들의 개인정보를 처리하면서 개인정보가 분실, 도난, 유출,
          변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은
          기술적/관리적 대책을 마련하고 있습니다.
        </p>
      </p>
      <p>
        <em>1. 비밀번호 암호화</em>
      </p>
      <p>
        ● 회원 이메일의 비밀번호는 암호화되어 저장 및 관리되고 있어 본인만이
        알고 있으며, 개인정보의 확인 및 변경도 비밀번호를 알고 있는 본인에
        의해서만 가능합니다.
      </p>
      <p>
        <em>2. 해킹 등에 대비한 대책</em>
      </p>
      <p>
        ● 회사는 해킹이나 바이러스 등에 의해 회원의 개인정보가 유출되거나
        훼손되는 것을 막기 위해 최선을 다하고 있습니다. 개인정보의 훼손에
        대비해서 자료를 수시로 백업하고 있고, 최신 백신프로그램을 이용하여
        이용자들의 개인정보나 자료가 유출되거나 손상되지 않도록 방지하고 있으며,
        암호화 통신 등을 통하여 네트워크상에서 개인정보를 안전하게 전송할 수
        있도록 하고 있습니다. 방화벽을 이용하여 외부로부터의 무단 접근을
        방지하고 있으며, 기타 시스템적으로 보안성을 확보하기 위한 가능한 모든
        기술적 장치를 갖추려 노력하고 있습니다.
      </p>
      <p>
        <em>3. 처리 직원의 최소화 및 교육</em>
      </p>
      <p>
        ● 회사의 개인정보관련 처리 직원은 담당자에 한정시키고 있고 이를 위한
        별도의 비밀번호를 부여하여 정기적으로 갱신하고 있으며, 담당자에 대한
        수시 교육을 통하여 개인정보처리방침의 준수를 항상 강조하고 있습니다.
      </p>
      <p>
        <em>4. 개인정보 보호전담기구의 운영</em>
      </p>
      <p>
        ● 사내 개인정보 보호전담기구를 통하여 개인정보 보호방침의 이행사항 및
        담당자의 준수 여부를 확인하여 문제가 발견될 경우 즉시 수정하고 바로 잡을
        수 있도록 노력하고 있습니다. 단, 이용자 본인의 부주의나 인터넷상의
        문제로 이메일주소, 비밀번호 등 개인정보가 유출되어 발생한 문제에 대해
        회사는 일체의 책임을 지지 않습니다.
      </p>
      <p>
        <strong>제9조 개인정보 보호책임자 및 담당자</strong>
      </p>
      <p>
        <em>1. 개인정보 관리 및 보호의 의무</em>
      </p>
      <p>
        ● 회사는 개인 정보를 안전하게 이용하여 최상의 서비스를 제공하기 위하여
        최선을 다하고 있습니다. 개인정보를 보호하는데 있어, 상기 고지한 사항에
        반하는 사고가 발생할 시에 개인정보보호 책임자가 책임을 집니다. 그러나
        기술적인 보완조치를 했음에도 불구하고, 해킹 등 기본적인 네트워크상의
        위험성에 의해 발생하는 예기치 못한 사고로 인한 정보의 훼손 및 방문자가
        작성한 게시물에 의한 각종 분쟁에 관해서는 일체 책임이 없습니다.
      </p>
      <p>
        <em>2. 담당부서 연락처</em>
      </p>
      <p>
        ● 서비스를 이용하면서 발생하는 모든 개인정보보호 관련 문의, 불만,
        조언이나 기타 사항은 개인정보 보호책임자 및 담당부서로 연락해주시기
        바랍니다. 아카에이아이는 이용자의 목소리에 귀 기울이며, 신속하고 충분한
        답변을 드릴 수 있도록 하겠습니다.
      </p>
      <p>
        [개인정보 보호책임자]
        <p>● 책임자 : 정명원</p>
        <p>● 직책 : 대표</p>
        <p>● 직급 : 대표</p>
        <p>● 문의 : 02-537-7201 , alive@akaai.kr</p>
      </p>
      <p>
        [개인정보 보호 담당부서]
        <p>● 부서명 : Alive 팀</p>
        <p>● 담당자 : 강병욱</p>
        <p>● 문의 : 02-537-7201 , alive@akaai.kr</p>
      </p>
      <p>
        기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에
        문의하시기 바랍니다.
        <p>● 개인정보침해신고센터 (privacy.kisa.or.kr / 국번 없이 118)</p>
        <p>● 대검찰청 사이버수사과 (spo.go.kr / 국번 없이 1301)</p>
        <p>● 경찰청 사이버안전국 (cyberbureau.police.go.kr / 국번 없이 182)</p>
      </p>
      <p>
        <strong>제10조 고지의 의무</strong>
        <p>
          현 개인정보처리방침 내용 추가, 삭제 및 수정이 있을 시에는 개정 최소
          7일 전부터 홈페이지의 '공지사항'을 통해 고지할 것입니다. 또한 개인정보
          보호정책에 버전번호 및 변경공고일자 및 그 시행일자 등을 부여하여
          개정여부를 쉽게 알 수 있도록 하고 있습니다.
        </p>
      </p>
      <p>
        ● 회사는 개인 정보를 안전하게 이용하여 최상의 서비스를 제공하기 위하여
        최선을 다하고 있습니다. 개인정보를 보호하는데 있어, 상기 고지한 사항에
        반하는 사고가 발생할 시에 개인정보보호 책임자가 책임을 집니다. 그러나
        기술적인 보완조치를 했음에도 불구하고, 해킹 등 기본적인 네트워크상의
        위험성에 의해 발생하는 예기치 못한 사고로 인한 정보의 훼손 및 방문자가
        작성한 게시물에 의한 각종 분쟁에 관해서는 일체 책임이 없습니다.
      </p>
      <p>개인정보처리방침 버전정보 : 1.0</p>
      <p>개인정보처리방침 공고일자 : 2024.01.16</p>
    </div>
  );
}

export default PrivacyKR;
