import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Main from './pages/Main/Main';
import Privacy from './pages/Privacy/Privacy';
import Terms from './pages/Terms/Terms';
import Disclaimer from './pages/Disclaimer/Disclaimer';
import PrivacyKR from './pages/Privacy/PrivacyKR';
import TermsKR from './pages/Terms/TermsKR';
import DeleteAccount from './pages/DeleteAccount/DeleteAccount';

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        {/* <Route path="/" element={<Login />} /> */}
        <Route path="/" element={<Main />} />
        <Route path="/disclaimer" element={<Disclaimer />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/kr/privacy" element={<PrivacyKR />} />
        <Route path="/kr/terms" element={<TermsKR />} />
        <Route path="/delete-account" element={<DeleteAccount />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
